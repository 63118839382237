import axios from 'axios';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'x-nellsroom-platform': 'web',
    'x-nellsroom-version': '1.0.0',
  },
});

export default apiClient;
