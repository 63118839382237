import apiClient from '../index';
import {
  AdminHighlightVideoDeleteResponse,
  AdminHighlightVideoItem,
  AdminHighlightVideoListResponse,
} from './type';

const AdminHighlightVideoApi = {
  getList: async () => {
    return apiClient.get<AdminHighlightVideoListResponse>(
      '/admin/highlight-videos',
    );
  },
  getById: async (id: number) => {
    return apiClient.get<AdminHighlightVideoItem>(
      `/admin/highlight-videos/${id}`,
    );
  },
  updateByIdAndParams: async (id: number, params: object) => {
    return await apiClient.put<AdminHighlightVideoItem>(
      `/admin/highlight-videos/${id}`,
      params,
    );
  },
  createByParams: async (params: object) => {
    return await apiClient.post<AdminHighlightVideoItem>(
      `/admin/highlight-videos`,
      params,
    );
  },
  deleteById: async (id: number) => {
    return await apiClient.delete<AdminHighlightVideoDeleteResponse>(
      `/admin/highlight-videos/${id}`,
    );
  },
};

export default AdminHighlightVideoApi;
