import apiClient from '../index';
import {
  VideoDetailResponse,
  VideoDetailVideoResponse,
  VideoListResponse,
} from './type';

const VideoApi = {
  getVideos: async () => {
    return apiClient.get<VideoListResponse>('/videos/v1/videos');
  },
  getVideoDetailById: async (id: number) => {
    return apiClient.get<VideoDetailResponse>(`/videos/v1/videos/${id}`);
  },
  getVideoDetailVideoById: async (id: number) => {
    return apiClient.get<VideoDetailVideoResponse>(
      `/videos/v1/videos/${id}/video`,
    );
  },
};

export default VideoApi;
