import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import AdminGnb from '../../components/AdminGnb';
import {
  Button,
  Col,
  Container,
  Nav,
  Row,
  Spinner,
  Table,
} from 'react-bootstrap';
import adminVideoApi from '../../api/adminVideoApi';
import { AdminVideoItem } from '../../api/adminVideoApi/type';
import { useSearchParams } from 'react-router-dom';
import timeService from '../../../services/timeService';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faComment } from '@fortawesome/free-regular-svg-icons';
import { AdminVideoCategoryItem } from '../../api/adminVideoCategoryApi/type';
import adminVideoCategoryApi from '../../api/adminVideoCategoryApi';
import _ from 'lodash';
import { Dictionary } from '@reduxjs/toolkit';
import Form from 'react-bootstrap/Form';

const AdminVideoListPage = () => {
  const [ready, setReady] = useState(false);
  const [videos, setVideos] = useState<AdminVideoItem[]>([]);
  const [hasPrev, setHasPrev] = useState(false);
  const [hasNext, setHasNext] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentVideoCategoryId, setCurrentVideoCategoryId] = useState<
    number | undefined
  >(undefined);
  const [videoCategories, setVideoCategories] = useState<
    AdminVideoCategoryItem[]
  >([]);
  const [videoCategoryById, setVideoCategoryById] =
    useState<Dictionary<AdminVideoCategoryItem>>();

  const [searchParams] = useSearchParams();

  const requestVideoCategories = useCallback(async () => {
    const res = await adminVideoCategoryApi.getAll();
    setVideoCategories(res.data.items);
  }, []);

  const requestVideoList = useCallback(
    async (videoCategoryId: number, page: number) => {
      const res = await adminVideoApi.getLatest(videoCategoryId, page);
      setVideos(res.data.items);
      setHasPrev(page > 1);
      setHasNext(res.data.hasNext);
    },
    [],
  );

  useEffect(() => {
    setVideoCategoryById(_.keyBy(videoCategories, 'id'));
  }, [videoCategories]);

  useEffect(() => {
    const page = searchParams.get('page')
      ? parseInt(searchParams.get('page')!!, 10)
      : 1;
    setCurrentPage(page);

    const videoCategoryId = searchParams.get('videoCategoryId')
      ? parseInt(searchParams.get('videoCategoryId')!!, 10)
      : 0;
    setCurrentVideoCategoryId(videoCategoryId);

    (async () => {
      await requestVideoCategories();
      await requestVideoList(videoCategoryId, page);
      setReady(true);
    })();
  }, []);

  const renderRow = useCallback(
    (video: AdminVideoItem, index: number) => {
      const videoCategory = videoCategoryById!![video.videoCategoryId];
      return (
        <tr key={index.toString()}>
          <td>{video.id}</td>
          <td>
            <img
              src={video.thumbnailImageUrl}
              width={100}
              className={'align-middle'}
            />
            <div className={'d-inline-block ms-2 align-middle'}>
              <div>
                <a href={`/admin/videos/${video.id}`} className={'text-white'}>
                  {video.title}
                </a>
              </div>
              <div>
                <FontAwesomeIcon icon={faThumbsUp} />
                <span className={'d-inline-block ms-1'}>{video.likeCount}</span>
                <FontAwesomeIcon icon={faComment} className={'ms-2'} />
                <span className={'d-inline-block ms-1'}>
                  {video.commentCount}
                </span>
              </div>
            </div>
          </td>
          <td>
            {videoCategory
              ? videoCategory.name
              : `Unknown Category (#${video.videoCategoryId})`}
          </td>
          <td>{timeService.convertSecondsForDisplay(video.duration)}</td>
          <td>
            {video.status === 0 && '비공개'}
            {video.status === 1 && '공개'}
            {video.status === 2 && '예약공개'}
          </td>
          <td>
            <a
              href={`/admin/video-comments?videoId=${video.id}`}
              className={'btn btn-sm btn-outline-light'}
            >
              댓글 관리
            </a>
          </td>
        </tr>
      );
    },
    [videoCategoryById],
  );

  const renderContent = () => {
    if (!ready) {
      return (
        <div className={'my-5 py-5 text-center'}>
          <Spinner />
        </div>
      );
    }

    return (
      <>
        <div className={'mt-4 d-flex'}>
          <Form.Select
            className={'w-auto d-inline-block'}
            onChange={e =>
              (window.location.href = `/admin/videos?videoCategoryId=${e.currentTarget.value}`)
            }
            defaultValue={currentVideoCategoryId}
          >
            <option value={0}>전체</option>
            {_.map(videoCategories, videoCategory => {
              return (
                <option value={videoCategory.id} key={videoCategory.id}>
                  {videoCategory.name}
                </option>
              );
            })}
          </Form.Select>
          <div className={'d-inline-block flex-grow-1'} />
          <Button
            href="/admin/video-comments"
            variant={'success'}
            className={'me-2'}
          >
            댓글 관리
          </Button>
          <Button href="/admin/video-categories" variant={'success'}>
            카테고리 관리
          </Button>
        </div>

        <Table striped bordered variant={'dark'} className={'mt-2'}>
          <thead>
            <tr>
              <th>#</th>
              <th>제목</th>
              <th>카테고리</th>
              <th>길이</th>
              <th>상태</th>
              <th>옵션</th>
            </tr>
          </thead>
          <tbody>{videos.map((video, index) => renderRow(video, index))}</tbody>
        </Table>

        <Row>
          {(hasPrev || hasNext) && (
            <>
              <Nav aria-label="Page navigation example" as={Col} md={'auto'}>
                <ul className="pagination">
                  {hasPrev && (
                    <li className="page-item">
                      <a
                        className="page-link"
                        href={`/admin/videos?page=${
                          currentPage - 1
                        }&videoCategoryId=${currentVideoCategoryId}`}
                      >
                        이전
                      </a>
                    </li>
                  )}
                  {hasNext && (
                    <li className="page-item">
                      <a
                        className="page-link"
                        href={`/admin/videos?page=${
                          currentPage + 1
                        }&videoCategoryId=${currentVideoCategoryId}`}
                      >
                        다음
                      </a>
                    </li>
                  )}
                </ul>
              </Nav>
            </>
          )}
          <Col />
          <Col md={'auto'}>
            <a href={'/admin/videos/new'} className={'btn btn-primary'}>
              새 동영상 등록
            </a>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>동영상 관리</title>
      </Helmet>
      <AdminGnb />
      <Container className={'mt-4'}>
        <h3>동영상 관리</h3>

        <div className={'my-4'}>{renderContent()}</div>
      </Container>
    </>
  );
};

export default AdminVideoListPage;
