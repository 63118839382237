import apiClient from '../index';
import { AdminMessageListResponse, AdminMessageSendResponse } from './type';

const AdminMessageApi = {
  getMessages: async (idLessThan: number | null) => {
    return await apiClient.get<AdminMessageListResponse>(`/admin/messages`, {
      params: {
        idLessThan,
      },
    });
  },
  send: async (
    receiverType: number,
    text: string | null,
    imageUrl: string | null,
    vimeoVideoId: string | null,
  ) => {
    return await apiClient.post<AdminMessageSendResponse>('/admin/messages', {
      receiverType,
      text,
      imageUrl,
      vimeoVideoId,
    });
  },
};

export default AdminMessageApi;
