import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import React, { useEffect } from 'react';
import { useAuth } from '../../hooks/useAuth';
import userApi from '../../api/userApi';
import { useUserStore } from '../../../store/user';
import { useAdmin } from '../../hooks/useAdmin';

const Gnb = () => {
  const { isLoggedIn } = useAuth();
  const { isAdmin } = useAdmin();
  const nickname = useUserStore(state => state.nickname);
  const refreshMe = React.useCallback(async () => {
    console.log('refreshMe called');
    const res = await userApi.me();
    useUserStore.getState().setNickname(res.data.nickname);
    useUserStore.getState().setRole(res.data.role);
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      refreshMe();
    }
  }, [isLoggedIn]);

  if ('ReactNativeWebView' in window) {
    return null;
  }

  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="/">Nell's Room</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {isLoggedIn && (
              <>
                {isAdmin && (
                  <>
                    <Nav.Link href="/admin">관리자 페이지</Nav.Link>
                  </>
                )}

                <Nav.Link href="/membership">멤버십 관리</Nav.Link>
              </>
            )}
          </Nav>
          <Nav>
            {isLoggedIn ? (
              <>
                <Nav.Link href="/logout">로그아웃</Nav.Link>
              </>
            ) : (
              <>
                <Nav.Link href="/login">로그인</Nav.Link>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Gnb;
