import { Container } from 'react-bootstrap';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import Gnb from '../../components/Gnb';

const AgreementPage = () => {
  const __html = require('./template.html').default;
  return (
    <>
      <Helmet>
        <title>이용 약관</title>
      </Helmet>
      <Gnb />
      <Container
        className={'mt-4'}
        dangerouslySetInnerHTML={{ __html: __html }}
      ></Container>
    </>
  );
};

export default AgreementPage;
