import apiClient from '../index';
import { NoticeItem, NoticeListResponse } from './type';

const NoticeApi = {
  getNotices: async (page: number) => {
    return await apiClient.get<NoticeListResponse>('/notice/v1/notices', {
      params: {
        page,
      },
    });
  },
  findById: async (id: number) => {
    return await apiClient.get<NoticeItem>(`/notice/v1/notices/${id}`, {});
  },
};

export default NoticeApi;
