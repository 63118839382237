import apiClient from '../index';
import {
  MembershipChangePaymentMethodResponse,
  MembershipSubscribeResponse,
  MembershipInfoResponse,
  MembershipSubscribeInfoResponse,
  MembershipOrderDetailResponse,
  MembershipSubscribeCancelResponse,
} from './type';

const membershipApi = {
  info: async () => {
    return await apiClient.get<MembershipInfoResponse>('/membership/v1/info');
  },
  getSubscribeInfo: async () => {
    return await apiClient.get<MembershipSubscribeInfoResponse>(
      `/membership/v1/subscribe`,
    );
  },
  subscribe: async (type: string) => {
    return await apiClient.post<MembershipSubscribeResponse>(
      `/membership/v1/subscribe?type=${type}`,
    );
  },
  changePaymentMethod: async () => {
    return await apiClient.put<MembershipChangePaymentMethodResponse>(
      `/membership/v1/payment-method`,
    );
  },
  cancelSubscribe: async () => {
    return await apiClient.post<MembershipSubscribeCancelResponse>(
      `/membership/v1/subscribe/cancel`,
    );
  },
  getOrder: async (orderCode: string) => {
    return await apiClient.get<MembershipOrderDetailResponse>(
      `/membership/v1/orders/${orderCode}`,
    );
  },
};

export default membershipApi;
