import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import Gnb from '../../components/Gnb';

const PrivacyPolicyPage = () => {
  const __html = require('./template.html').default;
  return (
    <>
      <Helmet>
        <title>개인정보 취급방침</title>
      </Helmet>
      <Gnb />
      <Container
        className={'mt-4'}
        dangerouslySetInnerHTML={{ __html: __html }}
      ></Container>
    </>
  );
};

export default PrivacyPolicyPage;
