import { Helmet } from 'react-helmet-async';
import { Container } from 'react-bootstrap';
import React from 'react';
import Gnb from '../../components/Gnb';

const FarewellPage = () => {
  const renderContent = () => {
    return (
      <div className={'mt-5'}>
        회원탈퇴가 완료되었습니다.
        <br />
        NELL'S ROOM 을 이용해주셔서 감사합니다.
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>회원탈퇴 완료</title>
      </Helmet>
      <Gnb />
      <Container>
        <h3 className={'mt-4 text-center'}>회원탈퇴 완료</h3>
        <div className={'mt-4'}>{renderContent()}</div>
      </Container>
    </>
  );
};

export default FarewellPage;
