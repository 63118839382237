import apiClient from '../index';
import { AdminAppConfigItem, AdminAppConfigUpdateResponse } from './type';

const AdminAppConfigApi = {
  getConfigByKey: async (key: string, options?: object) => {
    return await apiClient.get<AdminAppConfigItem>(
      `/admin/app-configs/${key}`,
      {
        ...options,
      },
    );
  },
  updateConfigByKeyAndValue: async (key: string, value: string | null) => {
    return await apiClient.put<AdminAppConfigUpdateResponse>(
      `/admin/app-configs/${key}`,
      {
        value,
      },
    );
  },
};

export default AdminAppConfigApi;
