import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import Gnb from '../../components/Gnb';
import { Container } from 'react-bootstrap';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useUserStore } from '../../../store/user';
import {
  VideoDetailResponse,
  VideoDetailVideoResponse,
} from '../../api/videoApi/type';
import videoApi from '../../api/videoApi';
import { NotFoundPage } from '../../components/NotFoundPage';
import { useParams } from 'react-router-dom';
import 'video.js/dist/video-js.css';
import VideoJS from './VideoJS';
import videojs from 'video.js';

export function VideoDetailPage() {
  const params = useParams();

  const role = useUserStore(state => state.role);
  const [videoId, setVideoId] = useState<number>();
  const [ready, setReady] = useState(false);
  const [videoDetail, setVideoDetail] = useState<VideoDetailResponse>();
  const [videoDetailVideo, setVideoDetailVideo] =
    useState<VideoDetailVideoResponse>();

  useEffect(() => {
    if (!role) {
      return;
    }

    if (role !== 'Admin') {
      return;
    }

    const videoId = params.videoId ? parseInt(params.videoId, 10) : undefined;
    setVideoId(videoId);
    setReady(true);
  }, [role, ready]);

  useEffect(() => {
    if (!ready) {
      return;
    }
    if (!videoId) {
      return;
    }

    (async () => {
      setVideoDetail((await videoApi.getVideoDetailById(videoId)).data);
      setVideoDetailVideo(
        (await videoApi.getVideoDetailVideoById(videoId)).data,
      );
    })();
  }, [ready, videoId]);

  const playerRef = React.useRef(null);

  const handlePlayerReady = player => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on('waiting', () => {
      videojs.log('player is waiting');
    });

    player.on('dispose', () => {
      videojs.log('player will dispose');
    });
  };

  const renderContent = useCallback(() => {
    if (role !== 'Admin') {
      return <NotFoundPage />;
    }

    if (!videoDetail || !videoDetailVideo) {
      return;
    }

    const videoJsOptions = {
      autoplay: false,
      controls: true,
      responsive: true,
      fluid: true,
      sources: [
        {
          src: videoDetailVideo.videoUrl,
          // type: 'video/mp4',
        },
      ],
    };

    return (
      <>
        <div>
          <p>{videoDetail.title}</p>
          <div>
            <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
          </div>
          <p>{videoDetail.summary}</p>
          <p>{videoDetail.detail}</p>
        </div>
        <script src="//vjs.zencdn.net/8.10.0/video.min.js"></script>
      </>
    );
  }, [role, videoDetail, videoDetailVideo]);

  return (
    <>
      <Helmet>
        <title>HomePage</title>
        <link href="//vjs.zencdn.net/8.10.0/video-js.css" rel="stylesheet" />
      </Helmet>
      <Gnb />
      <Container>
        <div className={'mt-4'}>{renderContent()}</div>
      </Container>
    </>
  );
}
