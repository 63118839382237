import { Helmet } from 'react-helmet-async';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AdminGnb from '../../components/AdminGnb';
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap';
import { NotFoundPage } from '../../components/NotFoundPage';
import adminFaqApi from '../../api/adminFaqApi';
import { AdminFaqItem } from '../../api/adminFaqApi/type';
import _ from 'lodash';
import adminFileApi from '../../api/adminFileApi';

const AdminFaqDetailPage = () => {
  const params = useParams();

  const [faqId, setFaqId] = useState<number>();
  const [faq, setFaq] = useState<AdminFaqItem>();

  const [title, setTitle] = useState('');
  const [content, setContent] = useState<string | null>(null);
  const [imageUrls, setImageUrls] = useState<string[]>();
  const [imageUrl, setImageUrl] = useState('');

  const [busy, setBusy] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const requestFaqVideoDetail = useCallback(async id => {
    try {
      const res = await adminFaqApi.findById(id);
      setFaq(res.data);
    } catch (_) {
      setFaqId(undefined);
    }
  }, []);

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isUploading) {
      return;
    }

    if (!e.currentTarget.files || e.currentTarget.files.length === 0) {
      setImageUrl('');
      return;
    }

    setIsUploading(true);

    try {
      const res = await adminFileApi.upload(e.currentTarget.files[0]);
      setImageUrl(res.data.uploadedUrl);
    } catch (_) {}

    setIsUploading(false);
  };

  useEffect(() => {
    if (!faq) {
      return;
    }

    setContent(faq.content);
    setTitle(faq.title);

    if (faq.attachedImageUrls) {
      setImageUrls(JSON.parse(faq.attachedImageUrls) as string[]);
    }
  }, [faq]);

  useEffect(() => {
    const faqId = params.faqId ? parseInt(params.faqId, 10) : undefined;
    setFaqId(faqId);

    (async () => {
      if (faqId) {
        await requestFaqVideoDetail(faqId);
      }
    })();
  }, []);

  const onClickDelete = async () => {
    if (!faq) {
      return;
    }

    if (busy) {
      return;
    }

    if (!window.confirm('FAQ를 삭제하시곘습니까?')) {
      return;
    }
    setBusy(true);

    try {
      await adminFaqApi.deleteById(faq.id);
      alert('정상적으로 삭제되었습니다.');
      window.location.href = '/admin/faqs';
    } catch (_) {
      setBusy(false);
    }
  };

  const onSubmit = async e => {
    e.preventDefault();

    if (busy) {
      return;
    }

    if (
      !window.confirm(
        faq ? '수정 내용을 반영하시겠습니까?' : '새 FAQ를 등록하시겠습니까?',
      )
    ) {
      return;
    }

    setBusy(true);

    try {
      const params = {
        title,
        content,
        attachedImageUrls: JSON.stringify(imageUrl ? [imageUrl] : imageUrls),
      };

      const res = faq
        ? await adminFaqApi.updateByIdAndParams(faq.id, params)
        : await adminFaqApi.createByParams(params);

      alert(
        faq
          ? '수정 내용이 정상적으로 반영되었습니다.'
          : '새로운 FAQ이 정상적으로 등록되었습니다.',
      );
      window.location.href = `/admin/faqs/${res.data.id}`;
    } catch (e) {
      setBusy(false);
    }
  };

  const renderContent = () => {
    return (
      <>
        {faqId && !faq && <NotFoundPage />}
        <>
          <Form onSubmit={onSubmit}>
            <Form.Group as={Row} className="mb-3" controlId="title">
              <Form.Label column sm={2}>
                제목
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name={'title'}
                  placeholder={faq?.title}
                  value={title}
                  onChange={e => setTitle(e.currentTarget.value)}
                  required
                  disabled={busy}
                />
              </Col>
            </Form.Group>
            {_.map(imageUrls, imageUrl => {
              return (
                <Form.Group as={Row} className="mb-3" controlId="content">
                  <Form.Label column sm={2}>
                    첨부된 이미지
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control type="text" value={imageUrl} disabled />
                    <Image src={imageUrl} style={{ maxWidth: '100%' }} />
                  </Col>
                </Form.Group>
              );
            })}
            <Form.Group as={Row} className="mb-3" controlId="image">
              <Form.Label column sm={2}>
                첨부 이미지
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type={'file'}
                  accept={'.jpg,.jpeg,.png'}
                  placeholder={'사용할 이미지 주소를 입력하세요.'}
                  onChange={onFileChange}
                  disabled={isUploading || busy}
                />
                <Image
                  src={imageUrl}
                  className={'mt-3'}
                  style={{ maxWidth: '100%' }}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="content">
              <Form.Label column sm={2}>
                내용
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  as={'textarea'}
                  name={'content'}
                  rows={5}
                  placeholder={faq?.content || ''}
                  value={content || ''}
                  onChange={e => setContent(e.currentTarget.value)}
                  required
                  disabled={busy}
                />
              </Col>
            </Form.Group>

            <div className={'d-flex'}>
              <div className={'d-inline-block flex-grow-1'}></div>

              {faqId && (
                <Button
                  className={'d-inline-block w-auto me-2'}
                  variant={'outline-danger'}
                  disabled={busy}
                  onClick={onClickDelete}
                >
                  삭제하기
                </Button>
              )}

              <Button
                type="submit"
                className={'d-inline-block w-auto'}
                disabled={busy}
              >
                {faqId ? '수정하기' : '등록하기'}
              </Button>
            </div>
          </Form>
        </>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>{`FAQ 관리 - ${faq ? faq.title : '등록하기'}`}</title>
      </Helmet>
      <AdminGnb />
      <Container className={'mt-4'}>
        <h3>FAQ 관리 - {faq ? faq.title : '등록하기'}</h3>
        <div className={'my-4'}>{renderContent()}</div>
      </Container>
    </>
  );
};

export default AdminFaqDetailPage;
