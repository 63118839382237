import apiClient from '../index';
import { FaqItem, FaqListResponse } from './type';

const FaqApi = {
  getFaqs: async (page: number) => {
    return await apiClient.get<FaqListResponse>('/faq/v1/faqs', {
      params: {
        page,
      },
    });
  },
  findById: async (id: number) => {
    return await apiClient.get<FaqItem>(`/faq/v1/faqs/${id}`, {});
  },
};

export default FaqApi;
