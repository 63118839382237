import React, { useEffect, useState } from 'react';
import { FaqItem } from '../../api/faqApi/type';
import { useParams } from 'react-router-dom';
import faqApi from '../../api/faqApi/faqApi';
import { Helmet } from 'react-helmet-async';
import Gnb from '../../components/Gnb';
import { Container, Image, ListGroup } from 'react-bootstrap';
import styled from 'styled-components/macro';
import moment from 'moment/moment';
import _ from 'lodash';

const FaqDetailPage = () => {
  const params = useParams();
  const [faqId, setFaqId] = useState(0);
  const [faq, setFaq] = useState<FaqItem>();

  const requestFaq = async id => {
    const res = await faqApi.findById(id);
    setFaq(res.data);
  };

  useEffect(() => {
    if (!faqId) {
      return;
    }
    requestFaq(faqId);
  }, [faqId]);

  useEffect(() => {
    setFaqId(parseInt(params.faqId!!, 10));
  }, []);

  const renderContent = () => {
    if (!faq) {
      return <></>;
    }

    return (
      <>
        <div className={'d-flex w-100 px-2 py-2 align-items-center'}>
          <div style={{ wordBreak: 'break-all' }}>
            <span style={{ fontSize: 18 }}>{faq.title}</span>
            <div
              className={'d-md-none mt-1'}
              style={{ fontSize: 14, color: '#9898AC' }}
            >
              {moment.utc(faq.createdAt).local().format('yyyy.MM.DD')}
            </div>
          </div>
          <div className={'flex-grow-1'}></div>
          <div
            className={'me-3 d-none d-md-block flex-shrink-0'}
            style={{ fontSize: 14, color: '#9898AC' }}
          >
            {moment.utc(faq.createdAt).local().format('yyyy.MM.DD')}
          </div>
        </div>
        <hr className={'mt-1'} />
        {faq.attachedImageUrls &&
          _.map(JSON.parse(faq.attachedImageUrls) as string[], imageUrl => {
            return (
              <div className={'mb-3 text-center'}>
                <Image src={imageUrl} style={{ maxWidth: '100%' }} />
              </div>
            );
          })}
        <div
          className={'pb-4 px-2'}
          style={{ fontSize: 14, wordBreak: 'break-all' }}
        >
          {faq.content &&
            _.map(faq.content.split('\n'), line => {
              return <div>{line}</div>;
            })}
        </div>
        <div className={'mt-4 px-2'}>
          <ul className={'pagination'}>
            <li className="page-item">
              <a
                className="page-link"
                href={`/faqs`}
                style={{
                  backgroundColor: '#222230',
                  color: 'white',
                  borderColor: '#9898ac',
                }}
              >
                목록
              </a>
            </li>
          </ul>
        </div>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>{`자주 묻는 질문 - ${faq?.title}`}</title>
      </Helmet>
      <Gnb />
      <Container className={'px-0 mt-4'}>{renderContent()}</Container>
    </>
  );
};

export default FaqDetailPage;
