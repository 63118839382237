import { Helmet } from 'react-helmet-async';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AdminGnb from '../../components/AdminGnb';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { NotFoundPage } from '../../components/NotFoundPage';
import { AdminVideoCategoryItem } from '../../api/adminVideoCategoryApi/type';
import adminVideoCategoryApi from '../../api/adminVideoCategoryApi';
import adminFileApi from '../../api/adminFileApi';

const AdminVideoCategoryDetailPage = () => {
  const params = useParams();

  const [videoCategoryId, setVideoCategoryId] = useState<number>();
  const [videoCategory, setVideoCategory] = useState<AdminVideoCategoryItem>();
  const [name, setName] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [order, setOrder] = useState(1);

  const [busy, setBusy] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const requestVideoCategoryDetail = useCallback(async (id: number) => {
    const res = await adminVideoCategoryApi.getById(id);
    setVideoCategory(res.data);
  }, []);

  useEffect(() => {
    if (!videoCategory) {
      return;
    }

    setName(videoCategory.name);
    setImageUrl(videoCategory.imageUrl);
    setOrder(videoCategory.order);
  }, [videoCategory]);

  useEffect(() => {
    const videoCategoryId = params.videoCategoryId
      ? parseInt(params.videoCategoryId, 10)
      : undefined;
    setVideoCategoryId(videoCategoryId);

    (async () => {
      if (videoCategoryId) {
        await requestVideoCategoryDetail(videoCategoryId);
      }
    })();
  }, []);

  const onSubmit = async e => {
    e.preventDefault();

    if (busy) {
      return;
    }

    if (
      !window.confirm(
        videoCategory
          ? '수정 내용을 반영하시겠습니까?'
          : '새 동영상을 등록하시겠습니까?',
      )
    ) {
      return;
    }

    setBusy(true);

    try {
      const res = videoCategory
        ? await adminVideoCategoryApi.updateByIdAndParams(videoCategory.id, {
            name,
            order,
            imageUrl,
          })
        : await adminVideoCategoryApi.createByParams({
            name,
            order,
            imageUrl,
          });

      alert(
        videoCategory
          ? '수정 내용이 정상적으로 반영되었습니다.'
          : '카테고리가 생성되었습니다.',
      );
      window.location.href = `/admin/video-categories/${res.data.id}`;
    } catch (e) {
      setBusy(false);
    }
  };

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isUploading) {
      return;
    }

    if (!e.currentTarget.files || e.currentTarget.files.length === 0) {
      setImageUrl(videoCategory?.imageUrl || '');
      return;
    }

    setIsUploading(true);

    try {
      const res = await adminFileApi.upload(e.currentTarget.files[0]);
      setImageUrl(res.data.uploadedUrl);
    } catch (_) {}

    setIsUploading(false);
  };

  const renderContent = () => {
    return (
      <>
        {videoCategoryId && !videoCategory && <NotFoundPage />}
        <>
          <Form onSubmit={onSubmit}>
            <Form.Group as={Row} className="mb-3" controlId="name">
              <Form.Label column sm={2}>
                이름
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name={'name'}
                  placeholder={videoCategory?.name}
                  value={name}
                  onChange={e => setName(e.currentTarget.value)}
                  required
                  disabled={busy}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="imageUrl">
              <Form.Label column sm={2}>
                이미지
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type={'file'}
                  accept={'.jpg,.jpeg,.png'}
                  placeholder={'사용할 이미지 주소를 입력하세요.'}
                  onChange={onFileChange}
                  disabled={isUploading || busy}
                />
                <img src={imageUrl} width={200} className={'mt-3'} />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="order">
              <Form.Label column sm={2}>
                순서
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="number"
                  name={'order'}
                  placeholder={videoCategory?.order?.toString()}
                  value={order.toString()}
                  onChange={e => setOrder(parseInt(e.currentTarget.value, 10))}
                  required
                  disabled={busy}
                />
              </Col>
            </Form.Group>

            <div className={'d-flex'}>
              <div className={'d-inline-block flex-grow-1'}></div>

              <Button
                type="submit"
                className={'d-inline-block w-auto'}
                disabled={busy}
              >
                {videoCategoryId ? '수정하기' : '등록하기'}
              </Button>
            </div>
          </Form>
        </>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>{`동영상 카테고리 관리 - ${
          videoCategory ? videoCategory.name : '등록하기'
        }`}</title>
      </Helmet>
      <AdminGnb />
      <Container className={'mt-4'}>
        <h3>
          동영상 카테고리 관리 -{' '}
          {videoCategory ? videoCategory.name : '등록하기'}
        </h3>
        <div className={'my-4'}>{renderContent()}</div>
      </Container>
    </>
  );
};

export default AdminVideoCategoryDetailPage;
