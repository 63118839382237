import { Helmet } from 'react-helmet-async';
import AdminGnb from '../../components/AdminGnb';
import { Accordion, Button, Container, Form } from 'react-bootstrap';
import React from 'react';
import BadWord from './BadWord';
import UserStoryAutoReplyContent from './UserStoryAutoReplyContent';
import StartScreenBackgroundImage from './StartScreenBackgroundImage';
import MessageRateLimit from './MessageRateLimit';
import MembershipSubscriptionType from './MembershipSubscriptionType';
import DefaultMessageContent from './DefaultMessageContent';

const AdminEtcPage = () => {
  const renderContent = () => {
    return (
      <>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>멤버십 상품 관리</Accordion.Header>
            <Accordion.Body>
              <MembershipSubscriptionType />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion className={'mt-3'}>
          <Accordion.Item eventKey="0">
            <Accordion.Header>욕설 관리</Accordion.Header>
            <Accordion.Body>
              <BadWord />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion className={'mt-3'}>
          <Accordion.Item eventKey="1">
            <Accordion.Header>메신저 기본 메시지 관리</Accordion.Header>
            <Accordion.Body>
              <DefaultMessageContent />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion className={'mt-3'}>
          <Accordion.Item eventKey="3">
            <Accordion.Header>메시지 전송 제한 관리</Accordion.Header>
            <Accordion.Body>
              <MessageRateLimit />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion className={'mt-3'}>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              망토라디오 자동 답장 메시지 관리
            </Accordion.Header>
            <Accordion.Body>
              <UserStoryAutoReplyContent />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion className={'mt-3'}>
          <Accordion.Item eventKey="2">
            <Accordion.Header>시작 화면 배경 이미지 관리</Accordion.Header>
            <Accordion.Body>
              <StartScreenBackgroundImage />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>기타 관리</title>
      </Helmet>
      <AdminGnb />
      <Container>
        <h3 className={'mt-4'}>기타 관리</h3>
        <div className={'mt-4'}>{renderContent()}</div>
      </Container>
    </>
  );
};

export default AdminEtcPage;
