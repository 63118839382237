import { Helmet } from 'react-helmet-async';
import AdminGnb from '../../components/AdminGnb';
import { Col, Container, Nav, Row, Table } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { AdminUserStoryItem } from '../../api/adminUserStoryApi/type';
import { useSearchParams } from 'react-router-dom';
import adminUserStoryApi from '../../api/adminUserStoryApi';
import _ from 'lodash';
import moment from 'moment';

const AdminUserStoryListPage = () => {
  const [searchParams] = useSearchParams();

  const [hasPrev, setHasPrev] = useState(false);
  const [hasNext, setHasNext] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [userStories, setUserStories] = useState<AdminUserStoryItem[]>([]);

  const requestUserStories = async (page: number) => {
    const res = await adminUserStoryApi.getUserStories(page);
    setUserStories(res.data.items);
    setHasPrev(page > 1);
    setHasNext(res.data.hasNext);
  };

  useEffect(() => {
    const page = searchParams.get('page')
      ? parseInt(searchParams.get('page')!!, 10)
      : 1;
    setCurrentPage(page);

    (async () => {
      await requestUserStories(page);
    })();
  }, []);

  const renderContent = () => {
    return (
      <>
        <Table striped bordered variant={'dark'}>
          <thead>
            <tr>
              <th>#</th>
              <th>내용</th>
              <th>신청자 닉네임</th>
              <th>신청시간</th>
            </tr>
          </thead>
          <tbody>
            {_.map(userStories, (userStory, index) => {
              return (
                <tr key={index.toString()}>
                  <td>#{userStory.id}</td>
                  <td>
                    {userStory.text.split('\n').map(str => (
                      <div>{str}</div>
                    ))}
                  </td>
                  <td>{userStory.user.nickname}</td>
                  <td>
                    {moment
                      .utc(userStory.user.createdAt)
                      .local()
                      .format('yyyy-MM-DD hh:mm:ss')}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>

        {(hasPrev || hasNext) && (
          <Nav aria-label="Page navigation example" as={Col} md={'auto'}>
            <ul className="pagination">
              {hasPrev && (
                <li className="page-item">
                  <a
                    className="page-link"
                    href={`/admin/user-stories?page=${currentPage - 1}`}
                  >
                    이전
                  </a>
                </li>
              )}
              {hasNext && (
                <li className="page-item">
                  <a
                    className="page-link"
                    href={`/admin/user-stories?page=${currentPage + 1}`}
                  >
                    다음
                  </a>
                </li>
              )}
            </ul>
          </Nav>
        )}
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>망토라디오 사연 목록</title>
      </Helmet>
      <AdminGnb />
      <Container>
        <h3 className={'mt-4'}>망토라디오 사연 목록</h3>
        <div className={'mt-4'}>{renderContent()}</div>
      </Container>
    </>
  );
};

export default AdminUserStoryListPage;
