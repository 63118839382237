import apiClient from '../index';
import { AdminUserMembershipListResponse } from './type';

const AdminUserMembershipApi = {
  get: async () => {
    return await apiClient.get<AdminUserMembershipListResponse>(
      '/admin/user-memberships',
    );
  },
};

export default AdminUserMembershipApi;
