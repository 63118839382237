import { Button, Col, Form, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import adminAppConfigApi from '../../api/adminAppConfigApi';

const BadWord = () => {
  const [ready, setReady] = useState(false);
  const [busy, setBusy] = useState(false);
  const [text, setText] = useState('');

  const requestGetBadWords = async () => {
    try {
      const res = await adminAppConfigApi.getConfigByKey('BadWords', {
        customErrorHandling: true,
      });
      setText(res.data.value || '');
    } catch (_) {}
  };

  const onSubmit = async e => {
    e.preventDefault();

    if (busy) {
      return;
    }

    const newBadWords = text.replace(/[\r\n\t\s]/gi, '');

    setBusy(true);
    try {
      await adminAppConfigApi.updateConfigByKeyAndValue(
        'BadWords',
        newBadWords,
      );
      alert('적용되었습니다.');
      await requestGetBadWords();
    } catch (_) {}
    setBusy(false);
  };

  useEffect(() => {
    (async () => {
      await requestGetBadWords();
      setReady(true);
    })();
  }, []);

  return (
    <>
      <p>쉼표(,)로 구분해서 적어주세요.</p>
      <Form onSubmit={onSubmit}>
        <Form.Group as={Row}>
          <Col sm={12}>
            <Form.Control
              as={'textarea'}
              placeholder={'욕설 목록을 입력하세요'}
              value={text}
              onChange={e => setText(e.currentTarget.value)}
              required
              disabled={!ready || busy}
            />
          </Col>
        </Form.Group>

        <div className={'text-end mt-3'}>
          <Button type={'submit'} disabled={!ready || busy}>
            저장
          </Button>
        </div>
      </Form>
    </>
  );
};

export default BadWord;
