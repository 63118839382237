import React, { useCallback, useEffect, useState } from 'react';
import Gnb from '../../components/Gnb';
import { Button, Container, Form } from 'react-bootstrap';
import userApi from '../../api/userApi';
import { isAxiosError } from 'axios';
import User from '../../../types/User';
import { useNavigate } from 'react-router-dom';
import AuthService from '../../../services/authService';
import Footer from '../../components/Footer';

const LoginPage = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [busy, setBusy] = useState(false);

  const onSubmit = async e => {
    e.preventDefault();

    if (busy) {
      return;
    }

    setBusy(true);

    try {
      const res = await userApi.signIn(email, password);
      AuthService.setAccessToken(res.data.accessToken);
      AuthService.setRefreshToken(res.data.refreshToken);

      const user: User = {
        email: res.data.email,
        nickname: res.data.nickname,
        role: res.data.userRole,
      };

      localStorage.setItem('user', JSON.stringify(user));
      navigate('/');
    } catch (e) {
      setBusy(false);
    }
  };

  return (
    <>
      <Gnb />
      <Container className={'mt-3 h-100'}>
        <Form onSubmit={onSubmit}>
          <Form.Group className="mb-3" controlId="login.inputEmail">
            <Form.Label>이메일</Form.Label>
            <Form.Control
              type="email"
              placeholder="name@example.com"
              onChange={e => setEmail(e.target.value)}
              required
              disabled={busy}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="login.inputPassword">
            <Form.Label>비밀번호</Form.Label>
            <Form.Control
              type="password"
              onChange={e => setPassword(e.target.value)}
              required
              disabled={busy}
            />
          </Form.Group>

          <div className={'text-end'}>
            <Button
              type={'submit'}
              className={'btn btn-sm btn-primary'}
              disabled={busy}
            >
              로그인
            </Button>
          </div>
        </Form>
      </Container>
      <Footer />
    </>
  );
};

export default LoginPage;
