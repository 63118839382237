import { Helmet } from 'react-helmet-async';
import React, { useCallback, useEffect, useState } from 'react';
import { AdminVideoItem } from '../../api/adminVideoApi/type';
import { useParams } from 'react-router-dom';
import AdminGnb from '../../components/AdminGnb';
import {
  Button,
  Col,
  Container,
  Form,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from 'react-bootstrap';
import { NotFoundPage } from '../../components/NotFoundPage';
import adminVideoApi from '../../api/adminVideoApi';
import { AdminVideoCategoryItem } from '../../api/adminVideoCategoryApi/type';
import adminVideoCategoryApi from '../../api/adminVideoCategoryApi';
import _ from 'lodash';
import moment from 'moment';
import adminVimeoApi from '../../api/adminVimeoApi';

const AdminVideoDetailPage = () => {
  const params = useParams();

  const [videoId, setVideoId] = useState<number>();

  const [videoCategories, setVideoCategories] = useState<
    AdminVideoCategoryItem[]
  >([]);
  const [video, setVideo] = useState<AdminVideoItem>();
  const [videoCategoryId, setVideoCategoryId] = useState(0);
  const [fullVimeoId, setFullVimeoId] = useState('');
  const [previewVimeoId, setPreviewVimeoId] = useState('');
  const [title, setTitle] = useState('');
  const [summary, setSummary] = useState('');
  const [detail, setDetail] = useState('');
  const [duration, setDuration] = useState(0);
  const [thumbnailImageUrl, setThumbnailImageUrl] = useState('');
  const [status, setStatus] = useState(1);
  const [openedAt, setOpenedAt] = useState<string | null>(null);

  const [busy, setBusy] = useState(false);

  const requestVideoCategories = useCallback(async () => {
    const res = await adminVideoCategoryApi.getAll();
    setVideoCategories(res.data.items);
  }, []);

  const requestVideoDetail = useCallback(async id => {
    try {
      const res = await adminVideoApi.getById(id);
      setVideo(res.data);
    } catch (_) {
      setVideoId(undefined);
    }
  }, []);

  useEffect(() => {
    if (!video) {
      return;
    }

    setVideoCategoryId(video.videoCategoryId);
    setFullVimeoId(video.fullVimeoId);
    setPreviewVimeoId(video.previewVimeoId);
    setTitle(video.title);
    setSummary(video.summary);
    setDetail(video.detail);
    setDuration(video.duration);
    setThumbnailImageUrl(video.thumbnailImageUrl);
    setStatus(video.status);
    setOpenedAt(
      video.openedAt
        ? moment.utc(video.openedAt).local().format('yyyy-MM-DD HH:mm:ss')
        : null,
    );
  }, [video]);

  useEffect(() => {
    const videoId = params.videoId ? parseInt(params.videoId, 10) : undefined;
    setVideoId(videoId);

    (async () => {
      await requestVideoCategories();

      if (videoId) {
        await requestVideoDetail(videoId);
      }
    })();
  }, []);

  const onClickVimeo = async () => {
    if (busy) {
      return;
    }

    const input = _.trim(
      window.prompt(
        '정보를 가져올 Vimeo 동영상 Id 또는 주소를 입력하세요.\n(예: 903558288 또는 https://vimeo.com/manage/videos/903558288)',
      ) || '',
    );

    if (!input) {
      return;
    }

    let inputVideoId: string;
    if (input.match(/^[0-9]+$/)) {
      inputVideoId = input;
    } else {
      const p = input.match(/videos\/([0-9]+)/);
      if (!p) {
        alert('입력한 정보에서 동영상 id 를 찾을 수 없습니다.');
        return;
      }

      inputVideoId = p[1];
    }

    setBusy(true);

    try {
      const res = await adminVimeoApi.getVideoDetail(inputVideoId);
      setTitle(res.data['name']);
      setThumbnailImageUrl(res.data['pictures']['base_link']);
      setDuration(res.data['duration']);
      setSummary(res.data['description']);
      setSummary(res.data['description']);
      setFullVimeoId(inputVideoId);
    } catch (_) {}

    setBusy(false);
  };

  const onSubmit = async e => {
    e.preventDefault();

    if (busy) {
      return;
    }

    if (
      !window.confirm(
        video
          ? '수정 내용을 반영하시겠습니까?'
          : '새 동영상을 등록하시겠습니까?',
      )
    ) {
      return;
    }

    setBusy(true);

    try {
      const res = video
        ? await adminVideoApi.updateByIdAndParams(video.id, {
            videoCategoryId,
            fullVimeoId,
            previewVimeoId,
            title,
            summary,
            detail,
            duration,
            thumbnailImageUrl,
            status,
            openedAt: openedAt
              ? moment(openedAt).utc().format('yyyy-MM-DD HH:mm:ss')
              : null,
          })
        : await adminVideoApi.createByParams({
            videoCategoryId,
            fullVimeoId,
            previewVimeoId,
            title,
            summary,
            detail,
            duration,
            thumbnailImageUrl,
            status,
            openedAt: openedAt
              ? moment(openedAt).utc().format('yyyy-MM-DD HH:mm:ss')
              : null,
          });

      alert(
        video
          ? '수정 내용이 정상적으로 반영되었습니다.'
          : '새로운 동영상이 정상적으로 등록되었습니다.',
      );
      window.location.href = `/admin/videos/${res.data.id}`;
    } catch (e) {
      setBusy(false);
    }
  };

  const renderContent = () => {
    return (
      <>
        {videoId && !video && <NotFoundPage />}
        <>
          <Form onSubmit={onSubmit}>
            <Form.Group as={Row} className="mb-3" controlId="videoCategory">
              <Form.Label column sm={2}>
                카테고리
              </Form.Label>
              <Col sm={10}>
                <Form.Select
                  name={'category'}
                  onChange={e =>
                    setVideoCategoryId(parseInt(e.currentTarget.value, 10))
                  }
                  required
                  disabled={busy}
                >
                  <option value={0} selected={videoCategoryId === 0}>
                    선택하세요
                  </option>
                  {_.map(videoCategories, videoCategory => {
                    return (
                      <option
                        value={videoCategory.id}
                        key={videoCategory.id}
                        selected={videoCategoryId === videoCategory.id}
                      >
                        {videoCategory.name}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="title">
              <Form.Label column sm={2}>
                제목
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name={'title'}
                  placeholder={video?.title}
                  value={title}
                  onChange={e => setTitle(e.currentTarget.value)}
                  required
                  disabled={busy}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="thumbnailImageUrl">
              <Form.Label column sm={2}>
                썸네일
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name={'thumbnailImageUrl'}
                  placeholder={video?.thumbnailImageUrl}
                  value={thumbnailImageUrl}
                  onChange={e => setThumbnailImageUrl(e.currentTarget.value)}
                  required
                  disabled={busy}
                />
                <img src={thumbnailImageUrl} width={200} className={'mt-3'} />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="summary">
              <Form.Label column sm={2}>
                짧은 내용
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  as={'textarea'}
                  rows={1}
                  name={'summary'}
                  placeholder={video?.summary}
                  value={summary}
                  onChange={e => setSummary(e.currentTarget.value)}
                  required
                  disabled={busy}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="detail">
              <Form.Label column sm={2}>
                전체 내용
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  as={'textarea'}
                  rows={5}
                  name={'detail'}
                  placeholder={video?.detail}
                  value={detail}
                  onChange={e => setDetail(e.currentTarget.value)}
                  required
                  disabled={busy}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="duration">
              <Form.Label column sm={2}>
                동영상 길이 (초)
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="number"
                  name={'duration'}
                  placeholder={video?.duration.toString()}
                  value={duration}
                  onChange={e =>
                    setDuration(parseInt(e.currentTarget.value, 10))
                  }
                  required
                  disabled={busy}
                />
              </Col>
            </Form.Group>
            <Row>
              <Col sm={6}>
                <Form.Group as={Row} className="mb-3" controlId="fullVimeoId">
                  <Form.Label column sm={4}>
                    풀버전 Vimeo Id
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                      type="text"
                      name={'fullVimeoId'}
                      placeholder={video?.fullVimeoId}
                      value={fullVimeoId}
                      onChange={e => setFullVimeoId(e.currentTarget.value)}
                      required
                      disabled={busy}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="previewVimeoId"
                >
                  <Form.Label column sm={4}>
                    프리뷰 Vimeo Id
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                      type="text"
                      name={'previewVimeoId'}
                      placeholder={video?.previewVimeoId}
                      value={previewVimeoId}
                      onChange={e => setPreviewVimeoId(e.currentTarget.value)}
                      required
                      disabled={busy}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <fieldset>
              <Form.Group as={Row} className="mb-3">
                <Form.Label as="legend" column sm={2}>
                  상태
                </Form.Label>
                <Col sm={10}>
                  <Form.Check
                    type="radio"
                    label="공개"
                    name="status"
                    checked={status === 1}
                    value={1}
                    onChange={() => setStatus(1)}
                    id={'status-1'}
                    disabled={busy}
                  />
                  <Form.Check
                    type="radio"
                    label="예약공개"
                    name="status"
                    checked={status === 2}
                    value={2}
                    onChange={() => setStatus(2)}
                    id={'status-2'}
                    disabled={busy}
                  />
                  <Form.Check
                    type="radio"
                    label="비공개"
                    name="status"
                    checked={status === 0}
                    value={0}
                    onChange={() => setStatus(0)}
                    id={'status-3'}
                    disabled={busy}
                  />
                </Col>
              </Form.Group>
            </fieldset>
            {status === 2 && (
              <Form.Group as={Row} className="mb-3" controlId="title">
                <Form.Label column sm={2}>
                  예약 공개 시간
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="text"
                    name={'openedAt'}
                    placeholder={
                      video && video.openedAt
                        ? moment
                            .utc(video.openedAt)
                            .local()
                            .format('yyyy-MM-DD HH:mm:ss')
                        : 'yyyy-MM-DD HH:mm:ss'
                    }
                    value={openedAt || ''}
                    onChange={e => setOpenedAt(e.currentTarget.value)}
                    required
                    disabled={busy}
                  />
                </Col>
              </Form.Group>
            )}

            <div className={'d-flex'}>
              <OverlayTrigger
                overlay={
                  <Tooltip>
                    풀버전 Vimeo 동영상 주소를 입력하면 Vimeo에 등록된 정보를
                    가져와 자동으로 입력합니다.
                  </Tooltip>
                }
              >
                <Button
                  variant={'success'}
                  onClick={onClickVimeo}
                  disabled={busy}
                >
                  Vimeo 동영상 정보 가져오기
                </Button>
              </OverlayTrigger>

              <div className={'d-inline-block flex-grow-1'}></div>

              <Button
                type="submit"
                className={'d-inline-block w-auto'}
                disabled={busy}
              >
                {videoId ? '수정하기' : '등록하기'}
              </Button>
            </div>
          </Form>
        </>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>{`동영상 관리 - ${video ? video.title : '등록하기'}`}</title>
      </Helmet>
      <AdminGnb />
      <Container className={'mt-4'}>
        <h3>동영상 관리 - {video ? video.title : '등록하기'}</h3>
        <div className={'my-4'}>{renderContent()}</div>
      </Container>
    </>
  );
};

export default AdminVideoDetailPage;
