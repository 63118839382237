import { Helmet } from 'react-helmet-async';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import Gnb from '../../components/Gnb';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import meApi from '../../api/meApi';
import { MeResponse } from '../../api/userApi/type';
import userApi from '../../api/userApi';

const MeWithdrawalPage = () => {
  const [busy, setBusy] = useState(false);
  const [ready, setReady] = useState(false);
  const [me, setMe] = useState<MeResponse>();

  const onClickCancel = useCallback(e => {
    e.preventDefault();

    window.history.back();
  }, []);

  useEffect(() => {
    (async () => {
      const res = await userApi.me();
      setMe(res.data);
      setReady(true);
    })();
  }, []);

  const onClickWithdrawal = useCallback(async e => {
    e.preventDefault();

    if (
      !window.confirm(
        '회원탈퇴 이후 회원탈퇴를 취소할 수 없습니다.\n정말 회원탈퇴를 진행하시겠습니까?',
      )
    ) {
      return;
    }

    if (busy) {
      return;
    }

    setBusy(true);

    try {
      const res = await meApi.withdrawal();
      window.location.href = res.data.navigateUrl;
    } catch (_) {}

    setBusy(false);
  }, []);

  const renderContent = () => {
    if (!ready) {
      return (
        <div className={'my-5 py-5 text-center'}>
          <Spinner />
        </div>
      );
    }

    return (
      <>
        <h4 className={'mt-5 mb-4'}>탈퇴 전 유의사항</h4>
        <ul>
          <li className={'mb-3'} style={{ color: '#9898ac' }}>
            탈퇴 후 동일한 이메일로 재가입이 불가능합니다.
          </li>
          {me && me.extra && (
            <>
              <li className={'mb-3'} style={{ color: '#9898ac' }}>
                구독이 활성화된 상태인 경우 회원탈퇴가 불가능합니다.
              </li>
              <li className={'mb-3'} style={{ color: '#9898ac' }}>
                남은 구독기간은 자동 소멸되며, 환불이 불가능합니다.
              </li>
            </>
          )}
          <li className={'mb-3'} style={{ color: '#9898ac' }}>
            법령에서 일정기간 정보 보관을 규정하거나 서비스 운영상 반드시 필요한
            경우, 개인정보취급방침에 공개한 내용과 같이 일정한 기간동안
            개인정보를 보관 후 파기합니다.
          </li>
        </ul>
        <div className={'flex-grow-1'} />
        <Row className={'mb-4'}>
          <Col>
            <a
              href="#"
              onClick={onClickWithdrawal}
              className={
                'd-flex w-100 text-decoration-none justify-content-center align-items-center'
              }
              style={{
                backgroundColor: '#494959',
                height: 40,
                borderRadius: 20,
                fontSize: 16,
                color: '#9898ac',
              }}
            >
              탈퇴하기
            </a>
          </Col>
          <Col>
            <a
              href="#"
              onClick={onClickCancel}
              className={
                'd-flex w-100 text-decoration-none text-black justify-content-center align-items-center'
              }
              style={{
                backgroundColor: '#FED86C',
                height: 40,
                borderRadius: 20,
                fontSize: 16,
                fontWeight: '700',
              }}
            >
              계속 이용하기
            </a>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>회원탈퇴</title>
      </Helmet>
      <Gnb />
      <Container className={'mt-4 d-flex flex-column flex-grow-1'}>
        <h3 className={'text-center'}>회원탈퇴</h3>
        {renderContent()}
      </Container>
    </>
  );
};

export default MeWithdrawalPage;
