import { Helmet } from 'react-helmet-async';
import { Container, Spinner } from 'react-bootstrap';
import Gnb from '../../components/Gnb';
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { FaqListItem } from '../../api/faqApi/type';
import { useSearchParams } from 'react-router-dom';
import faqApi from '../../api/faqApi/faqApi';
import _ from 'lodash';
import moment from 'moment';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

const FaqListPage = () => {
  const [searchParams] = useSearchParams();

  const [ready, setReady] = useState(false);
  const [faqs, setFaqs] = useState<FaqListItem[]>();
  const [hasPrev, setHasPrev] = useState(false);
  const [hasNext, setHasNext] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const requestFaqs = async (page: number) => {
    const res = await faqApi.getFaqs(page);
    setFaqs(res.data.items);
    setHasNext(res.data.hasNext);
  };

  useEffect(() => {
    const page = searchParams.get('page')
      ? parseInt(searchParams.get('page')!!, 10)
      : 1;
    setCurrentPage(page);
    setHasPrev(page > 1);

    (async () => {
      await requestFaqs(page);
      setReady(true);
    })();
  }, []);

  const renderList = () => {
    if (!ready) {
      return (
        <div className={'my-5 py-5 text-center'}>
          <Spinner />
        </div>
      );
    }

    if (!faqs || faqs.length === 0) {
      return (
        <>
          <div className={'my-5 py-5 text-center'}>
            <FontAwesomeIcon
              icon={faCircleInfo}
              className={'me-1'}
              color={'#BFBFD5'}
            />
            등록된 자주 묻는 질문이 없습니다
          </div>
        </>
      );
    }

    return (
      <>
        {_.map(faqs, (faq, index) => {
          return (
            <>
              {index > 0 && (
                <div
                  style={{
                    height: 1,
                    backgroundColor: '#494959',
                  }}
                ></div>
              )}
              <a
                href={`/faqs/${faq.id}`}
                className={
                  'd-flex w-100 py-3 align-items-center text-white text-decoration-none px-3'
                }
              >
                <div style={{ wordBreak: 'break-all' }}>
                  {faq.title}
                  <div
                    className={'d-md-none mt-1'}
                    style={{ fontSize: 14, color: '#9898AC' }}
                  >
                    {moment.utc(faq.createdAt).local().format('yyyy.MM.DD')}
                  </div>
                </div>
                <div className={'flex-grow-1'}></div>
                <div
                  className={'me-3 d-none d-md-block flex-shrink-0'}
                  style={{ fontSize: 14, color: '#9898AC' }}
                >
                  {moment.utc(faq.createdAt).local().format('yyyy.MM.DD')}
                </div>
                <div>
                  <FontAwesomeIcon icon={faChevronRight} />
                </div>
              </a>
            </>
          );
        })}
      </>
    );
  };

  const renderPagination = () => {
    if (!hasPrev && !hasNext) {
      return null;
    }

    return (
      <div className={'text-center mt-4'}>
        <div className={'d-inline-block'}>
          <ul className={'pagination'}>
            {hasPrev && (
              <li className="page-item">
                <a
                  className="page-link"
                  href={`/faqs?page=${currentPage - 1}`}
                  style={{
                    backgroundColor: '#222230',
                    color: 'white',
                    borderColor: '#9898ac',
                  }}
                >
                  이전
                </a>
              </li>
            )}
            {hasNext && (
              <li className="page-item">
                <a
                  className="page-link"
                  href={`/faqs?page=${currentPage + 1}`}
                  style={{
                    backgroundColor: '#222230',
                    color: 'white',
                    borderColor: '#9898ac',
                  }}
                >
                  다음
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <>
        {renderList()}
        {renderPagination()}
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>자주 묻는 질문</title>
      </Helmet>
      <Gnb />
      <Container>
        <h3 className={'mt-4'}>자주 묻는 질문</h3>
      </Container>
      <Container
        className={'px-0 mt-4'}
        style={{
          borderTopWidth: 1,
          borderTopColor: '#494959',
          borderTopStyle: 'solid',
          borderBottomWidth: 1,
          borderBottomColor: '#494959',
          borderBottomStyle: 'solid',
        }}
      >
        {renderContent()}
      </Container>
    </>
  );
};

export default FaqListPage;
