import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import AuthService from '../../services/authService';

const UserRoute = (): React.ReactElement | null => {
  const isAuthenticated = AuthService.getAccessToken() !== null;
  if (isAuthenticated === false) {
    if ('ReactNativeWebView' in window) {
      window['ReactNativeWebView'].postMessage(
        JSON.stringify({ type: 'tokenExpired' }),
      );
      return null;
    }

    return <Navigate to="/login" />;
  }

  return <Outlet />;
};

export default UserRoute;
