const TimeService = {
  convertSecondsForDisplay(v: number) {
    const hours = Math.floor(v / 3600);
    const minutes = Math.floor(v / 60) % 60;
    const seconds = v % 60;

    const displayHours = hours.toString();
    const displayMinutes =
      minutes >= 10 ? minutes.toString() : '0' + minutes.toString();
    const displaySeconds = seconds >= 10 ? seconds.toString() : '0' + seconds;

    return `${
      hours > 0 ? displayHours + ':' : ''
    }${displayMinutes}:${displaySeconds}`;
  },
};

export default TimeService;
