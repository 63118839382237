import React, { useEffect, useState } from 'react';
import { useAuth } from './useAuth';
import { useUserStore } from '../../store/user';
import { NotFoundPage } from '../components/NotFoundPage';
import User from '../../types/User';

export function useAdmin() {
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const rawUser = localStorage.getItem('user');
    const user: User | null = rawUser !== null && JSON.parse(rawUser);
    setIsAdmin(user?.role === 'Admin');
  }, []);

  return {
    isAdmin,
    setIsAdmin,
  };
}
