import { Helmet } from 'react-helmet-async';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AdminGnb from '../../components/AdminGnb';
import {
  Button,
  Col,
  Container,
  Form,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from 'react-bootstrap';
import { NotFoundPage } from '../../components/NotFoundPage';
import _ from 'lodash';
import adminVimeoApi from '../../api/adminVimeoApi';
import { AdminHighlightVideoItem } from '../../api/adminHighlightVideoApi/type';
import adminHighlightVideoApi from '../../api/adminHighlightVideoApi';

const AdminHighlightVideoDetailPage = () => {
  const params = useParams();

  const [highlightVideoId, setHighlightVideoId] = useState<number>();
  const [highlightVideo, setHighlightVideo] =
    useState<AdminHighlightVideoItem>();

  const [vimeoId, setVimeoId] = useState('');
  const [title, setTitle] = useState('');
  const [duration, setDuration] = useState(0);
  const [thumbnailImageUrl, setThumbnailImageUrl] = useState('');

  const [busy, setBusy] = useState(false);

  const requestHighlightVideoDetail = useCallback(async id => {
    try {
      const res = await adminHighlightVideoApi.getById(id);
      setHighlightVideo(res.data);
    } catch (_) {
      setHighlightVideoId(undefined);
    }
  }, []);

  useEffect(() => {
    if (!highlightVideo) {
      return;
    }

    setVimeoId(highlightVideo.vimeoId);
    setTitle(highlightVideo.title);
    setDuration(highlightVideo.duration);
    setThumbnailImageUrl(highlightVideo.thumbnailImageUrl);
  }, [highlightVideo]);

  useEffect(() => {
    const highlightVideoId = params.highlightVideoId
      ? parseInt(params.highlightVideoId, 10)
      : undefined;
    setHighlightVideoId(highlightVideoId);

    (async () => {
      if (highlightVideoId) {
        await requestHighlightVideoDetail(highlightVideoId);
      }
    })();
  }, []);

  const onClickVimeo = async () => {
    if (busy) {
      return;
    }

    const input = _.trim(
      window.prompt(
        '정보를 가져올 Vimeo 동영상 Id 또는 주소를 입력하세요.\n(예: 903558288 또는 https://vimeo.com/manage/videos/903558288)',
      ) || '',
    );

    if (!input) {
      return;
    }

    let inputVideoId: string;
    if (input.match(/^[0-9]+$/)) {
      inputVideoId = input;
    } else {
      const p = input.match(/videos\/([0-9]+)/);
      if (!p) {
        alert('입력한 정보에서 동영상 id 를 찾을 수 없습니다.');
        return;
      }

      inputVideoId = p[1];
    }

    setBusy(true);

    try {
      const res = await adminVimeoApi.getVideoDetail(inputVideoId);
      setVimeoId(inputVideoId);
      setTitle(res.data['name']);
      setThumbnailImageUrl(res.data['pictures']['base_link']);
      setDuration(res.data['duration']);
    } catch (_) {}

    setBusy(false);
  };

  const onClickDelete = async () => {
    if (!highlightVideo) {
      return;
    }

    if (busy) {
      return;
    }

    if (
      !window.confirm(
        '삭제한 동영상은 복구할 수 없습니다.\n정말 삭제하시곘습니까?',
      )
    ) {
      return;
    }
    setBusy(true);

    try {
      await adminHighlightVideoApi.deleteById(highlightVideo.id);
      alert('정상적으로 삭제되었습니다.');
      window.location.href = '/admin/highlight-videos';
    } catch (_) {
      setBusy(false);
    }
  };

  const onSubmit = async e => {
    e.preventDefault();

    if (busy) {
      return;
    }

    if (
      !window.confirm(
        highlightVideo
          ? '수정 내용을 반영하시겠습니까?'
          : '새 동영상을 등록하시겠습니까?',
      )
    ) {
      return;
    }

    setBusy(true);

    try {
      const res = highlightVideo
        ? await adminHighlightVideoApi.updateByIdAndParams(highlightVideo.id, {
            vimeoId,
            title,
            duration,
            thumbnailImageUrl,
          })
        : await adminHighlightVideoApi.createByParams({
            vimeoId,
            title,
            duration,
            thumbnailImageUrl,
          });

      alert(
        highlightVideo
          ? '수정 내용이 정상적으로 반영되었습니다.'
          : '새로운 동영상이 정상적으로 등록되었습니다.',
      );
      window.location.href = `/admin/highlight-videos/${res.data.id}`;
    } catch (e) {
      setBusy(false);
    }
  };

  const renderContent = () => {
    return (
      <>
        {highlightVideoId && !highlightVideo && <NotFoundPage />}
        <>
          <Form onSubmit={onSubmit}>
            <Form.Group as={Row} className="mb-3" controlId="title">
              <Form.Label column sm={2}>
                제목
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name={'title'}
                  placeholder={highlightVideo?.title}
                  value={title}
                  onChange={e => setTitle(e.currentTarget.value)}
                  required
                  disabled={busy}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="thumbnailImageUrl">
              <Form.Label column sm={2}>
                썸네일
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name={'thumbnailImageUrl'}
                  placeholder={highlightVideo?.thumbnailImageUrl}
                  value={thumbnailImageUrl}
                  onChange={e => setThumbnailImageUrl(e.currentTarget.value)}
                  required
                  disabled={busy}
                />
                <img src={thumbnailImageUrl} width={200} className={'mt-3'} />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="duration">
              <Form.Label column sm={2}>
                동영상 길이 (초)
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="number"
                  name={'duration'}
                  placeholder={highlightVideo?.duration.toString()}
                  value={duration}
                  onChange={e =>
                    setDuration(parseInt(e.currentTarget.value, 10))
                  }
                  required
                  disabled={busy}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="vimeoId">
              <Form.Label column sm={2}>
                Vimeo Id
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name={'vimeoId'}
                  placeholder={highlightVideo?.vimeoId}
                  value={vimeoId}
                  onChange={e => setVimeoId(e.currentTarget.value)}
                  required
                  disabled={busy}
                />
              </Col>
            </Form.Group>
            <div className={'d-flex'}>
              <OverlayTrigger
                overlay={
                  <Tooltip>
                    Vimeo 동영상 주소를 입력하면 Vimeo에 등록된 정보를 가져와
                    자동으로 입력합니다.
                  </Tooltip>
                }
              >
                <Button
                  variant={'success'}
                  onClick={onClickVimeo}
                  disabled={busy}
                >
                  Vimeo 동영상 정보 가져오기
                </Button>
              </OverlayTrigger>

              <div className={'d-inline-block flex-grow-1'}></div>

              {highlightVideoId && (
                <Button
                  className={'d-inline-block w-auto me-2'}
                  variant={'outline-danger'}
                  disabled={busy}
                  onClick={onClickDelete}
                >
                  삭제하기
                </Button>
              )}

              <Button
                type="submit"
                className={'d-inline-block w-auto'}
                disabled={busy}
              >
                {highlightVideoId ? '수정하기' : '등록하기'}
              </Button>
            </div>
          </Form>
        </>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>{`세로 동영상 관리 - ${
          highlightVideo ? highlightVideo.title : '등록하기'
        }`}</title>
      </Helmet>
      <AdminGnb />
      <Container className={'mt-4'}>
        <h3>
          세로 동영상 관리 -{' '}
          {highlightVideo ? highlightVideo.title : '등록하기'}
        </h3>
        <div className={'my-4'}>{renderContent()}</div>
      </Container>
    </>
  );
};

export default AdminHighlightVideoDetailPage;
