import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import React, { useEffect } from 'react';
import { useAuth } from '../../hooks/useAuth';
import userApi from '../../api/userApi';
import { useUserStore } from '../../../store/user';
import { useAdmin } from '../../hooks/useAdmin';

const AdminGnb = () => {
  const { isLoggedIn } = useAuth();
  const { isAdmin } = useAdmin();
  const nickname = useUserStore(state => state.nickname);
  const refreshMe = React.useCallback(async () => {
    console.log('refreshMe called');
    const res = await userApi.me();
    useUserStore.getState().setNickname(res.data.nickname);
    useUserStore.getState().setRole(res.data.role);
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      refreshMe();
    }
  }, [isLoggedIn]);

  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="/admin">관리자 페이지</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/admin/users">유저</Nav.Link>
            <Nav.Link href="/admin/highlight-videos">세로 동영상</Nav.Link>
            <Nav.Link href="/admin/videos">동영상</Nav.Link>
            <Nav.Link href="/admin/live">라이브</Nav.Link>
            <Nav.Link href="/admin/messages">메신저</Nav.Link>
            <Nav.Link href="/admin/notices">공지사항</Nav.Link>
            <Nav.Link href="/admin/faqs">FAQ</Nav.Link>
            <Nav.Link href="/admin/notifications/send">푸시</Nav.Link>
            <Nav.Link href="/admin/etc">기타</Nav.Link>
          </Nav>
          <Nav>
            {!isLoggedIn && <Nav.Link href="/login">Login</Nav.Link>}
            {isLoggedIn && (
              <NavDropdown title={nickname} id="basic-nav-dropdown">
                <NavDropdown.Item href="/">일반 페이지</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">
                  Another action
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">
                  Something
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/logout">Logout</NavDropdown.Item>
              </NavDropdown>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default AdminGnb;
