import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import AdminGnb from '../../components/AdminGnb';
import {
  Button,
  Col,
  Container,
  Image,
  Nav,
  Row,
  Spinner,
  Table,
} from 'react-bootstrap';
import adminVideoApi from '../../api/adminVideoApi';
import {
  AdminVideoCommentItem,
  AdminVideoItem,
} from '../../api/adminVideoApi/type';
import { useSearchParams } from 'react-router-dom';

const AdminVideoCommentListPage = () => {
  const [ready, setReady] = useState(false);
  const [videoComments, setVideoComments] = useState<AdminVideoCommentItem[]>(
    [],
  );
  const [hasPrev, setHasPrev] = useState(false);
  const [hasNext, setHasNext] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentVideoId, setCurrentVideoId] = useState<number | undefined>(
    undefined,
  );
  const [currentVideo, setCurrentVideo] = useState<
    AdminVideoItem | undefined
  >();

  const [searchParams] = useSearchParams();

  const requestVideoCommentList = useCallback(
    async (videoId: number | undefined, page: number) => {
      const res = await adminVideoApi.getLatestComments(videoId, page);
      setVideoComments(res.data.items);
      setHasPrev(page > 1);
      setHasNext(res.data.hasNext);
    },
    [],
  );

  const onClickDelete = useCallback(
    async (videoComment: AdminVideoCommentItem) => {
      if (!window.confirm('정말 댓글을 삭제하시겠습니까?')) {
        return;
      }

      try {
        await adminVideoApi.deleteCommentById(videoComment.id);
        alert('댓글이 삭제되었습니다.');
        window.location.reload();
      } catch {}
    },
    [],
  );

  useEffect(() => {
    if (!currentVideoId) {
      setCurrentVideo(undefined);
      return;
    }

    (async () => {
      const res = await adminVideoApi.getById(currentVideoId);
      setCurrentVideo(res.data);
    })();
  }, [currentVideoId]);

  useEffect(() => {
    const page = searchParams.get('page')
      ? parseInt(searchParams.get('page')!!, 10)
      : 1;
    setCurrentPage(page);

    const videoId = searchParams.get('videoId')
      ? parseInt(searchParams.get('videoId')!!, 10)
      : undefined;
    setCurrentVideoId(videoId);

    (async () => {
      await requestVideoCommentList(videoId, page);
      setReady(true);
    })();
  }, []);

  const renderRow = useCallback(
    (videoComment: AdminVideoCommentItem, index: number) => {
      const user = videoComment.user;
      const video = videoComment.video;

      return (
        <tr key={index.toString()}>
          <td>
            <div className={'d-flex align-items-center'}>
              <>
                {user.profileImageUrl ? (
                  <Image
                    src={user.profileImageUrl}
                    width={40}
                    height={40}
                    roundedCircle
                  />
                ) : (
                  <div
                    className={'rounded-circle bg-body-secondary bg-black'}
                    style={{ width: 40, height: 40 }}
                  ></div>
                )}
              </>

              <div className={'ms-2'}>
                <p className={'mb-0'}>{user.nickname}</p>
                <p className={'mb-0 text-white-50'}>{user.email}</p>
              </div>
            </div>
          </td>
          <td>
            <a
              href={`/admin/video-comments?videoId=${video.id}`}
              className={'text-white'}
            >
              {video.title}
            </a>
          </td>
          <td>{videoComment.content}</td>
          <td>
            <Button
              variant={'outline-danger'}
              size={'sm'}
              onClick={() => {
                onClickDelete(videoComment);
              }}
            >
              삭제
            </Button>
          </td>
        </tr>
      );
    },
    [],
  );

  const renderContent = () => {
    if (!ready) {
      return (
        <div className={'my-5 py-5 text-center'}>
          <Spinner />
        </div>
      );
    }

    return (
      <>
        <Table striped bordered variant={'dark'} className={'mt-2'} size={'sm'}>
          <colgroup>
            <col width={100} />
            <col width={200} />
            <col />
            <col width={60} />
          </colgroup>
          <thead>
            <tr>
              <th>유저</th>
              <th>동영상</th>
              <th>내용</th>
              <th>옵션</th>
            </tr>
          </thead>
          <tbody>
            {videoComments.map((videoComment, index) =>
              renderRow(videoComment, index),
            )}
          </tbody>
        </Table>

        <Row>
          {(hasPrev || hasNext) && (
            <>
              <Nav aria-label="Page navigation example" as={Col} md={'auto'}>
                <ul className="pagination">
                  {hasPrev && (
                    <li className="page-item">
                      <a
                        className="page-link"
                        href={`/admin/video-comments?page=${currentPage - 1}${
                          currentVideoId ? `&videoId=${currentVideoId}` : ''
                        }`}
                      >
                        이전
                      </a>
                    </li>
                  )}
                  {hasNext && (
                    <li className="page-item">
                      <a
                        className="page-link"
                        href={`/admin/video-comments?page=${currentPage + 1}${
                          currentVideoId ? `&videoId=${currentVideoId}` : ''
                        }`}
                      >
                        다음
                      </a>
                    </li>
                  )}
                </ul>
              </Nav>
            </>
          )}
        </Row>
      </>
    );
  };

  const titleSuffix = currentVideo ? ` - ${currentVideo.title}` : '';

  return (
    <>
      <Helmet>
        <title>{`동영상 댓글 관리${titleSuffix}`}</title>
      </Helmet>
      <AdminGnb />
      <Container className={'mt-4'}>
        <h3>동영상 댓글 관리</h3>
        {currentVideo && (
          <div>
            <p>선택된 동영상: {currentVideo.title}</p>
            <a
              href={'/admin/video-comments'}
              className={'btn btn-sm btn-outline-info'}
            >
              동영상 선택을 해제하고 전체 댓글 보기
            </a>
          </div>
        )}

        <div className={'my-4'}>{renderContent()}</div>
      </Container>
    </>
  );
};

export default AdminVideoCommentListPage;
