import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import Gnb from '../../components/Gnb';
import { Container, Image, Row, Spinner } from 'react-bootstrap';
import membershipApi from '../../api/membershipApi';
import { useEffect, useState } from 'react';
import { MembershipSubscribeInfoResponse } from '../../api/membershipApi/type';
import _ from 'lodash';
import CheckIcon from './membership-check.svg';
import CloseIcon from './membership-close.svg';
import MembershipRight from './membership-right.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export function MembershipPage() {
  const [ready, setReady] = useState(false);
  const [busyMonthly, setBusyMonthly] = useState(false);
  const [busyYearly, setBusyYearly] = useState(false);
  const [membershipSubscribeInfo, setMembershipSubscribeInfo] =
    useState<MembershipSubscribeInfoResponse>();

  const onClickMonthlyMembership = async e => {
    e.preventDefault();

    if (busyMonthly || busyYearly) {
      return;
    }

    setBusyMonthly(true);

    try {
      const res = await membershipApi.subscribe('month');
      window.location.href = res.data.navigateUrl;
    } finally {
      setBusyMonthly(false);
    }
  };

  const onClickYearlyMembership = async e => {
    e.preventDefault();

    if (busyMonthly || busyYearly) {
      return;
    }

    setBusyYearly(true);

    try {
      const res = await membershipApi.subscribe('year');
      window.location.href = res.data.navigateUrl;
    } finally {
      setBusyYearly(false);
    }
  };

  const requestMembershipSubscribeInfo = async () => {
    const res = await membershipApi.getSubscribeInfo();
    setMembershipSubscribeInfo(res.data);
  };

  useEffect(() => {
    (async () => {
      await requestMembershipSubscribeInfo();
      setReady(true);
    })();
  }, []);

  const renderContent = () => {
    if (!ready) {
      return (
        <div className={'my-5 py-5 text-center'}>
          <Spinner />
        </div>
      );
    }
    return (
      <>
        <h3 className={'fw-bold mb-4 text-center mt-4'}>멤버십 혜택</h3>
        {_.indexOf(membershipSubscribeInfo!!.types, 'year') !== -1 && (
          <div
            style={{
              background: 'linear-gradient(#717183, #4949597f)',
              paddingTop: 16,
              paddingBottom: 10,
              borderRadius: 20,
            }}
          >
            <div style={{ paddingLeft: 20, paddingRight: 20 }}>
              <div className={'fw-bold'} style={{ fontSize: 20 }}>
                BASIC
              </div>
              <div className={'fw-bold'} style={{ fontSize: 14 }}>
                ₩38,000 / 월
              </div>
              <div style={{ marginTop: 20 }}>
                <div className={'d-flex'}>
                  <div>NELL’S ROOM 모든 풀영상 시청</div>
                </div>
                <div className={'d-flex'} style={{ marginTop: 10 }}>
                  <div>온라인 음감회 자유 시청</div>
                </div>
                <div className={'d-flex'} style={{ marginTop: 10 }}>
                  <div>MD 상품 선주문 혜택</div>
                </div>
                <div className={'d-flex'} style={{ marginTop: 10 }}>
                  <div>NELL 멤버들과의 프라이빗 메신저 기능</div>
                </div>
                <div className={'d-flex'} style={{ marginTop: 10 }}>
                  <div>
                    <span style={{ color: '#FED86C' }}>공연 선예매권</span>
                    <br />
                    <div
                      className={'d-inline-block ms-3'}
                      style={{ fontSize: 16 }}
                    >
                      국내 단독 공연 좌석 중 80% OPEN
                    </div>
                  </div>
                </div>
                <br />
                <br />
              </div>
            </div>
            <a
              href="#"
              className={
                'd-flex text-decoration-none align-items-center justify-content-center'
              }
              onClick={onClickMonthlyMembership}
              style={{
                color: 'black',
                height: 40,
                marginTop: 24,
                marginLeft: 10,
                marginRight: 10,
                backgroundColor: '#FED86C',
                borderRadius: 20,
                fontSize: 16,
                fontWeight: '700',
              }}
            >
              {busyMonthly ? (
                <FontAwesomeIcon
                  icon={faSpinner}
                  fontSize={20}
                  color={'black'}
                  className={'fa-spin'}
                />
              ) : (
                '구독하기'
              )}
            </a>
          </div>
        )}

        {_.indexOf(membershipSubscribeInfo!!.types, 'month') !== -1 && (
          <div
            style={{
              background: 'linear-gradient(#433980, #3131647f)',
              position: 'relative',
              paddingTop: 16,
              paddingBottom: 10,
              borderRadius: 20,
              marginTop: 20,
            }}
          >
            <Image
              src={MembershipRight}
              style={{ position: 'absolute', top: 0, right: 0, bottom: 0 }}
            />
            <div style={{ paddingLeft: 20, paddingRight: 20 }}>
              <div className={'fw-bold'} style={{ fontSize: 20 }}>
                PREMIUM
              </div>
              <div className={'fw-bold'} style={{ fontSize: 14 }}>
                ₩418,000 / 연
              </div>
              <div style={{ marginTop: 20 }}>
                <div className={'d-flex'}>
                  <div>NELL’S ROOM 모든 풀영상 시청</div>
                </div>
                <div className={'d-flex'} style={{ marginTop: 10 }}>
                  <div>온라인 음감회 자유 시청</div>
                </div>
                <div className={'d-flex'} style={{ marginTop: 10 }}>
                  <div>MD 상품 선주문 혜택</div>
                </div>
                <div className={'d-flex'} style={{ marginTop: 10 }}>
                  <div>NELL 멤버들과의 프라이빗 메신저 기능</div>
                </div>
                <div className={'d-flex'} style={{ marginTop: 10 }}>
                  <div>
                    <span style={{ color: '#FED86C' }}>공연 선예매권</span>
                    <br />
                    <div
                      className={'d-inline-block ms-3'}
                      style={{ fontSize: 16 }}
                    >
                      국내 단독 공연 좌석 중 80% OPEN
                    </div>
                  </div>
                </div>
                <div className={'d-flex'} style={{ marginTop: 10 }}>
                  <div>
                    <span style={{ color: '#FED86C' }}>
                      공연 밋앤그릿(Meet & Greet) 추첨
                    </span>
                    <br />
                    <div
                      className={'d-inline-block ms-3'}
                      style={{ fontSize: 16 }}
                    >
                      연 3회 추첨 기회 제공
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <a
              href="#"
              onClick={onClickYearlyMembership}
              className={
                'd-flex text-decoration-none align-items-center justify-content-center'
              }
              style={{
                color: 'black',
                height: 40,
                marginTop: 16,
                marginLeft: 10,
                marginRight: 10,
                backgroundColor: '#FED86C',
                borderRadius: 20,
                fontSize: 16,
                fontWeight: '700',
              }}
            >
              {busyYearly ? (
                <FontAwesomeIcon
                  icon={faSpinner}
                  fontSize={20}
                  color={'black'}
                  className={'fa-spin'}
                />
              ) : (
                '구독하기'
              )}
            </a>
          </div>
        )}

        <template>
          <h3
            style={{
              color: 'white',
              fontSize: 20,
              fontWeight: '700',
              marginTop: 60,
              marginBottom: 10,
            }}
          >
            우주유랑단 가입 혜택
          </h3>
          <div>
            <div className={'d-flex'}>
              <div
                className={
                  'flex-grow-1 flex-column d-flex fw-bold justify-content-end'
                }
                style={{ paddingTop: 8, paddingBottom: 8 }}
              >
                기능
              </div>
              <div
                className={
                  'fw-bold d-flex flex-column align-items-center justify-content-center flex-shrink-0'
                }
                style={{ width: 100, paddingTop: 8, paddingBottom: 8 }}
              >
                BASIC
                <span style={{ fontSize: 12 }}>₩38,000 / 월</span>
              </div>
              <div
                className={
                  'fw-bold d-flex flex-column align-items-center justify-content-center flex-shrink-0'
                }
                style={{
                  width: 100,
                  paddingTop: 8,
                  paddingBottom: 8,
                  borderTopColor: '#FECF48',
                  borderTopWidth: 1,
                  borderTopStyle: 'solid',
                  borderLeftColor: '#FECF48',
                  borderLeftWidth: 1,
                  borderLeftStyle: 'solid',
                  borderRightColor: '#FECF48',
                  borderRightWidth: 1,
                  borderRightStyle: 'solid',
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                }}
              >
                PREMIUM
                <span style={{ fontSize: 12 }}>₩418,000 / 연</span>
              </div>
            </div>
            <div className={'d-flex'}>
              <div
                className={'flex-grow-1'}
                style={{
                  paddingTop: 16,
                  paddingBottom: 16,
                  borderTopWidth: 1,
                  borderTopColor: '#494959',
                  borderTopStyle: 'solid',
                }}
              >
                풀영상 시청
              </div>
              <div
                className={
                  'fw-bold d-flex justify-content-center flex-shrink-0'
                }
                style={{
                  width: 100,
                  paddingTop: 16,
                  paddingBottom: 16,
                  borderTopWidth: 1,
                  borderTopColor: '#494959',
                  borderTopStyle: 'solid',
                }}
              >
                <Image src={CheckIcon} width={20} />
              </div>
              <div
                className={
                  'fw-bold d-flex justify-content-center flex-shrink-0'
                }
                style={{
                  width: 100,
                  paddingTop: 16,
                  paddingBottom: 16,
                  borderTopWidth: 1,
                  borderTopColor: '#494959',
                  borderTopStyle: 'solid',
                  borderLeftColor: '#FECF48',
                  borderLeftWidth: 1,
                  borderLeftStyle: 'solid',
                  borderRightColor: '#FECF48',
                  borderRightWidth: 1,
                  borderRightStyle: 'solid',
                }}
              >
                <Image src={CheckIcon} width={20} />
              </div>
            </div>
            <div className={'d-flex'}>
              <div
                className={'flex-grow-1'}
                style={{
                  paddingTop: 16,
                  paddingBottom: 16,
                  borderTopWidth: 1,
                  borderTopColor: '#494959',
                  borderTopStyle: 'solid',
                }}
              >
                온라인 음감회 <br className={'d-sm-none'} />
                자유시청
              </div>
              <div
                className={
                  'fw-bold d-flex justify-content-center flex-shrink-0'
                }
                style={{
                  width: 100,
                  paddingTop: 16,
                  paddingBottom: 16,
                  borderTopWidth: 1,
                  borderTopColor: '#494959',
                  borderTopStyle: 'solid',
                }}
              >
                <Image src={CheckIcon} width={20} />
              </div>
              <div
                className={
                  'fw-bold d-flex justify-content-center flex-shrink-0'
                }
                style={{
                  width: 100,
                  paddingTop: 16,
                  paddingBottom: 16,
                  borderTopWidth: 1,
                  borderTopColor: '#494959',
                  borderTopStyle: 'solid',
                  borderLeftColor: '#FECF48',
                  borderLeftWidth: 1,
                  borderLeftStyle: 'solid',
                  borderRightColor: '#FECF48',
                  borderRightWidth: 1,
                  borderRightStyle: 'solid',
                }}
              >
                <Image src={CheckIcon} width={20} />
              </div>
            </div>
            <div className={'d-flex'}>
              <div
                className={'flex-grow-1'}
                style={{
                  paddingTop: 16,
                  paddingBottom: 16,
                  borderTopWidth: 1,
                  borderTopColor: '#494959',
                  borderTopStyle: 'solid',
                }}
              >
                MD 상품
                <br className={'d-sm-none'} />
                선주문 혜택
              </div>
              <div
                className={
                  'fw-bold d-flex justify-content-center flex-shrink-0'
                }
                style={{
                  width: 100,
                  paddingTop: 16,
                  paddingBottom: 16,
                  borderTopWidth: 1,
                  borderTopColor: '#494959',
                  borderTopStyle: 'solid',
                }}
              >
                <Image src={CheckIcon} width={20} />
              </div>
              <div
                className={
                  'fw-bold d-flex justify-content-center flex-shrink-0'
                }
                style={{
                  width: 100,
                  paddingTop: 16,
                  paddingBottom: 16,
                  borderTopWidth: 1,
                  borderTopColor: '#494959',
                  borderTopStyle: 'solid',
                  borderLeftColor: '#FECF48',
                  borderLeftWidth: 1,
                  borderLeftStyle: 'solid',
                  borderRightColor: '#FECF48',
                  borderRightWidth: 1,
                  borderRightStyle: 'solid',
                }}
              >
                <Image src={CheckIcon} width={20} />
              </div>
            </div>
            <div className={'d-flex'}>
              <div
                className={'flex-grow-1'}
                style={{
                  paddingTop: 16,
                  paddingBottom: 16,
                  borderTopWidth: 1,
                  borderTopColor: '#494959',
                  borderTopStyle: 'solid',
                }}
              >
                NELL 멤버들과의 <br className={'d-sm-none'} />
                프라이빗 메신저 기능
              </div>
              <div
                className={
                  'fw-bold d-flex justify-content-center flex-shrink-0'
                }
                style={{
                  width: 100,
                  paddingTop: 16,
                  paddingBottom: 16,
                  borderTopWidth: 1,
                  borderTopColor: '#494959',
                  borderTopStyle: 'solid',
                }}
              >
                <Image src={CheckIcon} width={20} />
              </div>
              <div
                className={
                  'fw-bold d-flex justify-content-center flex-shrink-0'
                }
                style={{
                  width: 100,
                  paddingTop: 16,
                  paddingBottom: 16,
                  borderTopWidth: 1,
                  borderTopColor: '#494959',
                  borderTopStyle: 'solid',
                  borderLeftColor: '#FECF48',
                  borderLeftWidth: 1,
                  borderLeftStyle: 'solid',
                  borderRightColor: '#FECF48',
                  borderRightWidth: 1,
                  borderRightStyle: 'solid',
                }}
              >
                <Image src={CheckIcon} width={20} />
              </div>
            </div>
            <div className={'d-flex'}>
              <div
                className={'flex-grow-1'}
                style={{
                  paddingTop: 16,
                  paddingBottom: 16,
                  borderTopWidth: 1,
                  borderTopColor: '#494959',
                  borderTopStyle: 'solid',
                }}
              >
                공연 선예매권
                <br />
                (국내 공연 좌석 중 80%)
              </div>
              <div
                className={
                  'fw-bold d-flex justify-content-center flex-shrink-0'
                }
                style={{
                  width: 100,
                  paddingTop: 16,
                  paddingBottom: 16,
                  borderTopWidth: 1,
                  borderTopColor: '#494959',
                  borderTopStyle: 'solid',
                }}
              >
                <Image src={CheckIcon} width={20} />
              </div>
              <div
                className={
                  'fw-bold d-flex justify-content-center flex-shrink-0'
                }
                style={{
                  width: 100,
                  paddingTop: 16,
                  paddingBottom: 16,
                  borderTopWidth: 1,
                  borderTopColor: '#494959',
                  borderTopStyle: 'solid',
                  borderLeftColor: '#FECF48',
                  borderLeftWidth: 1,
                  borderLeftStyle: 'solid',
                  borderRightColor: '#FECF48',
                  borderRightWidth: 1,
                  borderRightStyle: 'solid',
                }}
              >
                <Image src={CheckIcon} width={20} />
              </div>
            </div>
            <div className={'d-flex align-items-stretch'}>
              <div
                className={'flex-grow-1'}
                style={{
                  paddingTop: 16,
                  paddingBottom: 16,
                  borderTopWidth: 1,
                  borderTopColor: '#494959',
                  borderTopStyle: 'solid',
                }}
              >
                공연 밋앤그릿
                <br className={'d-sm-none'} />
                추첨 (연3회)
              </div>
              <div
                className={
                  'fw-bold d-flex justify-content-center flex-shrink-0'
                }
                style={{
                  width: 100,
                  paddingTop: 16,
                  paddingBottom: 16,
                  borderTopWidth: 1,
                  borderTopColor: '#494959',
                  borderTopStyle: 'solid',
                }}
              >
                <Image src={CloseIcon} width={20} />
              </div>
              <div
                className={
                  'fw-bold d-flex justify-content-center flex-shrink-0'
                }
                style={{
                  width: 100,
                  paddingTop: 16,
                  paddingBottom: 16,
                  borderTopWidth: 1,
                  borderTopColor: '#494959',
                  borderTopStyle: 'solid',
                  borderLeftColor: '#FECF48',
                  borderLeftWidth: 1,
                  borderLeftStyle: 'solid',
                  borderRightColor: '#FECF48',
                  borderRightWidth: 1,
                  borderRightStyle: 'solid',
                  borderBottomColor: '#FECF48',
                  borderBottomWidth: 1,
                  borderBottomStyle: 'solid',
                  borderBottomLeftRadius: 10,
                  borderBottomRightRadius: 10,
                }}
              >
                <Image src={CheckIcon} width={20} />
              </div>
            </div>
          </div>
          <h3
            style={{
              color: 'white',
              fontSize: 20,
              fontWeight: '700',
              marginTop: 60,
              marginBottom: 10,
            }}
          >
            안내사항
          </h3>
          <ul>
            <li>멤버십은 상시 가입 가능합니다.</li>
            <li>멤버십 유효기간은 가입일로부터 월 30일, 연 365일입니다.</li>
            <li>
              멤버십 구독 후 7일 이내 환불 가능합니다. 단, 일부라도 멤버십
              혜택을 받은 경우 환불이 불가합니다.
            </li>
            <li>
              멤버십 혜택은 멤버십 가입자 본인에게만 제공되며 타인에게 판매 및
              양도할 수 없습니다. 타인에게 양도 시 이에 대한 피해 책임은 양도한
              회원 본인에게 있습니다.
            </li>
          </ul>
        </template>
        <div>
          우유여러분께 더 나은 혜택을 제공하기 위해 멤버십 혜택을 준비중입니다.
        </div>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>멤버십 관리</title>
      </Helmet>
      <Gnb />
      <Container>{renderContent()}</Container>
    </>
  );
}
