import apiClient from '../index';
import { AdminLiveInfoResponse, AdminLiveUpdateResponse } from './type';

const AdminLiveApi = {
  getLiveInfo: async () => {
    return await apiClient.get<AdminLiveInfoResponse>('/admin/live');
  },
  updateLiveInfo: async (
    isLive: boolean,
    vimeoEventId: string | null,
    title: string | null,
    thumbnailImageUrl: string | null,
    summary: string | null,
    chatEmbedUrl: string | null,
    membershipOnly: boolean | null,
  ) => {
    await apiClient.put<AdminLiveUpdateResponse>('/admin/live', {
      isLive,
      vimeoEventId,
      title,
      thumbnailImageUrl,
      summary,
      chatEmbedUrl,
      membershipOnly,
    });
  },
};

export default AdminLiveApi;
