import { Navigate } from 'react-router-dom';
import React from 'react';
import AuthService from '../../../services/authService';

const LogoutPage = () => {
  AuthService.setAccessToken(null);
  AuthService.setRefreshToken(null);
  localStorage.removeItem('user');
  console.log('removeAccessToken by logoutPage');
  return <Navigate to={'/'} />;
};

export default LogoutPage;
