import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Col, Container, Nav, Row, Table } from 'react-bootstrap';
import timeService from '../../../services/timeService';
import { AdminHighlightVideoItem } from '../../api/adminHighlightVideoApi/type';
import adminHighlightVideoApi from '../../api/adminHighlightVideoApi';
import AdminGnb from '../../components/AdminGnb';

const AdminHighlightVideoListPage = () => {
  const [highlightVideos, setHighlightVideos] = useState<
    AdminHighlightVideoItem[]
  >([]);

  const requestHighlightVideos = async () => {
    const res = await adminHighlightVideoApi.getList();
    setHighlightVideos(res.data.items);
  };

  useEffect(() => {
    requestHighlightVideos();
  }, []);

  const renderRow = useCallback(
    (highlightVideo: AdminHighlightVideoItem, index: number) => {
      return (
        <tr key={index.toString()}>
          <td>{highlightVideo.id}</td>
          <td>
            <img
              src={highlightVideo.thumbnailImageUrl}
              width={100}
              className={'align-middle'}
            />
            <div className={'d-inline-block ms-2 align-middle'}>
              <div>
                <a
                  href={`/admin/highlight-videos/${highlightVideo.id}`}
                  className={'text-white'}
                >
                  {highlightVideo.title}
                </a>
              </div>
            </div>
          </td>
          <td>
            {timeService.convertSecondsForDisplay(highlightVideo.duration)}
          </td>
        </tr>
      );
    },
    [],
  );

  const renderContent = () => {
    return (
      <>
        <Table striped bordered variant={'dark'}>
          <thead>
            <tr>
              <th>#</th>
              <th>제목</th>
              <th>길이</th>
            </tr>
          </thead>
          <tbody>
            {highlightVideos.map((highlightVideo, index) =>
              renderRow(highlightVideo, index),
            )}
          </tbody>
        </Table>

        <div className={'text-end mt-4'}>
          <a href={'/admin/highlight-videos/new'} className={'btn btn-primary'}>
            새 동영상 등록
          </a>
        </div>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>세로 동영상 관리</title>
      </Helmet>
      <AdminGnb />
      <Container>
        <h3 className={'mt-4'}>세로 동영상 관리</h3>
        <div className={'mt-4'}>{renderContent()}</div>
      </Container>
    </>
  );
};

export default AdminHighlightVideoListPage;
