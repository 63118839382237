import apiClient from '../index';
import {
  AdminNoticeDeleteResponse,
  AdminNoticeItem,
  AdminNoticeListResponse,
} from './type';

const AdminNoticeApi = {
  getNotices: async (page: number) => {
    return await apiClient.get<AdminNoticeListResponse>('/admin/notices', {
      params: {
        page,
      },
    });
  },
  findById: async (id: number) => {
    return await apiClient.get<AdminNoticeItem>(`/admin/notices/${id}`);
  },
  deleteById: async (id: number) => {
    return await apiClient.delete<AdminNoticeDeleteResponse>(
      `/admin/notices/${id}`,
    );
  },
  createByParams: async (params: object) => {
    return await apiClient.post<AdminNoticeItem>(`/admin/notices`, params);
  },
  updateByIdAndParams: async (id: number, params: object) => {
    return await apiClient.put<AdminNoticeItem>(`/admin/notices/${id}`, params);
  },
};

export default AdminNoticeApi;
