import { Helmet } from 'react-helmet-async';
import AdminGnb from '../../components/AdminGnb';
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap';
import React, { useRef, useState } from 'react';
import adminFileApi from '../../api/adminFileApi';
import _ from 'lodash';
import adminMessageApi from '../../api/adminMessageApi';

const AdminMessageSendPage = () => {
  const [isUploading, setIsUploading] = useState(false);
  const [busy, setBusy] = useState(false);

  const [receiverType, setReceiverType] = useState(1);
  const [text, setText] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [vimeoUrl, setVimeoUrl] = useState('');

  const inputFileRef = useRef<HTMLInputElement>(null);

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isUploading) {
      return;
    }

    if (!e.currentTarget.files || e.currentTarget.files.length === 0) {
      setImageUrl('');
      return;
    }

    console.log(e.currentTarget.files);

    setIsUploading(true);

    try {
      const res = await adminFileApi.upload(e.currentTarget.files[0]);
      setImageUrl(res.data.uploadedUrl);
    } catch (_) {}

    setIsUploading(false);
  };

  const onSubmit = async e => {
    e.preventDefault();

    if (busy) {
      return;
    }

    const textToSend = _.trim(text) || null;
    const imageUrlToSend = _.trim(imageUrl) || null;
    const vimeoUrlToSend = _.trim(vimeoUrl) || null;
    let vimeoVideoId: string | null = null;

    if (!textToSend && !imageUrlToSend && !vimeoUrlToSend) {
      alert('내용, 이미지, Vimeo 동영상 주소가 모두 비었습니다.');
      return;
    }

    if (vimeoUrlToSend != null) {
      if (vimeoUrlToSend.match(/^[0-9]+$/)) {
        vimeoVideoId = vimeoUrlToSend;
      } else {
        const p = vimeoUrlToSend.match(/videos\/([0-9]+)/);
        if (!p) {
          alert('입력한 정보에서 동영상 id 를 찾을 수 없습니다.');
          return;
        }

        vimeoVideoId = p[1];
      }
    }

    setBusy(true);

    try {
      await adminMessageApi.send(
        receiverType,
        textToSend,
        imageUrlToSend,
        vimeoVideoId,
      );

      alert('메시지 발송이 요청되었습니다.');
      setText('');
      setVimeoUrl('');
      setImageUrl('');
      if (inputFileRef.current) {
        inputFileRef.current.value = '';
      }
    } catch {}

    setBusy(false);
  };

  const renderContent = () => {
    return (
      <div>
        <Form onSubmit={onSubmit}>
          <fieldset>
            <Form.Group as={Row} className="mb-3">
              <Form.Label as="legend" column sm={2}>
                상태
              </Form.Label>
              <Col sm={10}>
                <Form.Check
                  type="radio"
                  label="연간 + 월간 멤버십"
                  name="receiverType"
                  checked={receiverType === 1}
                  value={1}
                  onChange={() => setReceiverType(1)}
                  id={'receiverType-1'}
                  disabled={busy}
                />
                <Form.Check
                  type="radio"
                  label="연간 멤버십"
                  name="receiverType"
                  checked={receiverType === 2}
                  value={2}
                  onChange={() => setReceiverType(2)}
                  id={'receiverType-2'}
                  disabled={busy}
                />
                <Form.Check
                  type="radio"
                  label="월간 멤버십"
                  name="receiverType"
                  checked={receiverType === 3}
                  value={3}
                  onChange={() => setReceiverType(3)}
                  id={'receiverType-3'}
                  disabled={busy}
                />
              </Col>
            </Form.Group>
          </fieldset>
          <Form.Group as={Row} className="mb-3" controlId="text">
            <Form.Label column sm={2}>
              내용
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                as={'textarea'}
                rows={5}
                name={'text'}
                placeholder={'내용을 입력하세요.'}
                value={text}
                onChange={e => setText(e.currentTarget.value)}
                disabled={busy}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="imageUrl">
            <Form.Label column sm={2}>
              이미지
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                ref={inputFileRef}
                type={'file'}
                accept={'.jpg,.jpeg,.png'}
                placeholder={'사용할 이미지 주소를 입력하세요.'}
                onChange={onFileChange}
                disabled={isUploading || busy}
              />
              {imageUrl && (
                <Image src={imageUrl} width={200} className={'mt-3'} />
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="vimeoUrl">
            <Form.Label column sm={2}>
              Vimeo 동영상 주소
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                name={'vimeoUrl'}
                placeholder={'Vimeo 동영상 주소를 입력하세요.'}
                value={vimeoUrl}
                onChange={e => setVimeoUrl(e.currentTarget.value)}
                disabled={busy}
              />
            </Col>
          </Form.Group>

          <div className={'d-flex'}>
            <div className={'d-inline-block flex-grow-1'}></div>

            <Button
              type={'submit'}
              className={'d-inline-block w-auto me-2'}
              disabled={busy}
            >
              발송하기
            </Button>
          </div>
        </Form>
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>메시지 발송하기</title>
      </Helmet>
      <AdminGnb />
      <Container>
        <h3 className={'mt-4'}>메시지 발송하기</h3>
        <div className={'mt-4'}>{renderContent()}</div>
      </Container>
    </>
  );
};

export default AdminMessageSendPage;
