import React from 'react';
import { Container } from 'react-bootstrap';

const Footer = () => {
  if ('ReactNativeWebView' in window) {
    return null;
  }

  return (
    <Container
      style={{
        borderTopWidth: 1,
        borderTopStyle: 'solid',
        borderTopColor: '#808080',
        fontSize: 12,
        color: '#efefef',
      }}
      className={'px-0 py-4'}
    >
      (주)스페이스보헤미안 | 이메일 :{' '}
      <a
        href="mailto:nellsroom_support@nell.band"
        style={{ color: '#efefef', textDecoration: 'none' }}
      >
        nellsroom_support@nell.band
      </a>
    </Container>
  );
};

export default Footer;
