import apiClient from '../index';
import { MeResponse, TokenRefreshResponse, UserSignInResponse } from './type';

const userApi = {
  signIn: async (email: string, password: string) => {
    return await apiClient.post<UserSignInResponse>('/users/v1/sign-in', {
      email,
      password,
    });
  },
  me: async () => {
    return await apiClient.get<MeResponse>('/users/v1/me');
  },
  tokenRefresh: async (refreshToken: string) => {
    return await apiClient.post<TokenRefreshResponse>(
      '/users/v1/token/refresh',
      {
        refreshToken,
      },
    );
  },
};

export default userApi;
