import { Helmet } from 'react-helmet-async';
import AdminGnb from '../../components/AdminGnb';
import { Container, Table } from 'react-bootstrap';
import React, { useCallback, useEffect, useState } from 'react';
import { AdminVideoCategoryItem } from '../../api/adminVideoCategoryApi/type';
import adminVideoCategoryApi from '../../api/adminVideoCategoryApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment, faThumbsUp } from '@fortawesome/free-regular-svg-icons';

const AdminVideoCategoryListPage = () => {
  const [videoCategories, setVideoCategories] = useState<
    AdminVideoCategoryItem[]
  >([]);

  const requestVideoCategories = async () => {
    const res = await adminVideoCategoryApi.getAll();
    setVideoCategories(res.data.items);
  };

  useEffect(() => {
    requestVideoCategories();
  }, []);

  const renderRow = useCallback(
    (videoCategory: AdminVideoCategoryItem, index: number) => {
      return (
        <tr key={index.toString()}>
          <td>{index + 1}</td>
          <td>
            <img
              src={videoCategory.imageUrl}
              width={100}
              className={'align-middle'}
            />
            <div className={'d-inline-block ms-2 align-middle'}>
              <a
                href={`/admin/video-categories/${videoCategory.id}`}
                className={'text-white'}
              >
                {videoCategory.name}
              </a>
            </div>
          </td>
        </tr>
      );
    },
    [videoCategories],
  );

  const renderContent = () => {
    return (
      <>
        <Table striped bordered variant={'dark'}>
          <thead>
            <tr>
              <th>#</th>
              <th>카테고리</th>
            </tr>
          </thead>
          <tbody>
            {videoCategories.map((videoCategory, index) =>
              renderRow(videoCategory, index),
            )}
          </tbody>
        </Table>

        <div>
          <a href={'/admin/video-categories/new'} className={'btn btn-primary'}>
            새 카테고리 생성
          </a>
        </div>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>동영상 카테고리 관리</title>
      </Helmet>
      <AdminGnb />
      <Container>
        <h3 className={'mt-4'}>동영상 카테고리 관리</h3>
        <div className={'mt-4'}>{renderContent()}</div>
      </Container>
    </>
  );
};

export default AdminVideoCategoryListPage;
