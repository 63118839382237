import { Button, Container, Image, Spinner, Table } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import AdminGnb from '../../components/AdminGnb';
import { Helmet } from 'react-helmet-async';
import adminUserApi from '../../api/adminUserApi';
import { AdminUserItem } from '../../api/adminUserApi/type';
import { AdminUserSubscriptionItem } from '../../api/adminUserSubscriptionApi/type';
import adminUserSubscriptionApi from '../../api/adminUserSubscriptionApi';
import _ from 'lodash';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { Headers, Data } from 'react-csv/lib/core';
import { AdminUserAddressItem } from '../../api/adminUserAddressApi/type';
import adminUserAddressApi from '../../api/adminUserAddressApi';
import { AdminUserMembershipItem } from '../../api/adminUserMembershipApi/type';
import adminUserMembershipApi from '../../api/adminUserMembershipApi';

const AdminUserListPage = () => {
  const [ready, setReady] = useState(false);

  const [type, setType] = useState('all');
  const [userList, setUserList] = useState<AdminUserItem[]>([]);
  const [userListToDisplay, setUserListToDisplay] = useState<AdminUserItem[]>(
    [],
  );
  const [userSubscriptionList, setUserSubscriptionList] =
    useState<AdminUserSubscriptionItem[]>();
  const [userMembershipList, setUserMembershipList] =
    useState<AdminUserMembershipItem[]>();
  const [userAddressList, setUserAddressList] = useState<
    AdminUserAddressItem[]
  >([]);

  const [csvExportHeaders, setCsvExportHeaders] = useState<Headers>([]);
  const [csvExportData, setCsvExportData] = useState<Data>([]);
  const [csvExportFileName, setCsvExportFileName] = useState<string>();

  const requestUsers = async () => {
    const res = await adminUserApi.getUsers();
    setUserList(res.data.items);
  };

  const requestUserSubscriptions = async () => {
    const res = await adminUserSubscriptionApi.get();
    setUserSubscriptionList(res.data.items);
  };

  const requestUserMemberships = async () => {
    const res = await adminUserMembershipApi.get();
    setUserMembershipList(res.data.items);
  };

  const requestUserAddresses = async () => {
    const res = await adminUserAddressApi.get();
    setUserAddressList(res.data.items);
  };

  useEffect(() => {
    (async () => {
      await requestUsers();
      await requestUserSubscriptions();
      await requestUserMemberships();
      await requestUserAddresses();
      setReady(true);
    })();
  }, []);

  useEffect(() => {
    if (type === 'all') {
      setUserListToDisplay(userList);
      return;
    }

    const userSubscriptionByUserId = _.keyBy(userSubscriptionList, 'userId');
    const filtered = _.filter(userList, user => {
      const userSubscription = userSubscriptionByUserId[user.id];
      return (
        userSubscription &&
        moment.utc(userSubscription.endDateTime).isAfter(moment.utc())
      );
    });

    if (type === 'membership') {
      setUserListToDisplay(filtered);
      return;
    }

    if (type === 'membershipYear') {
      setUserListToDisplay(
        _.filter(filtered, user => {
          const userSubscription = userSubscriptionByUserId[user.id]!!;
          return userSubscription.interval === 'YEAR';
        }),
      );
      return;
    }

    if (type === 'membershipMonth') {
      setUserListToDisplay(
        _.filter(filtered, user => {
          const userSubscription = userSubscriptionByUserId[user.id]!!;
          return userSubscription.interval === 'MONTH';
        }),
      );
      return;
    }
  }, [type, userList, userSubscriptionList]);

  useEffect(() => {
    setCsvExportHeaders([
      { label: '#', key: 'id' },
      { label: '이메일', key: 'email' },
      { label: '닉네임', key: 'nickname' },
      { label: '멤버십 종류', key: 'membershipType' },
      { label: '멤버십 시작일', key: 'membershipStartDate' },
      { label: '멤버십 종료일', key: 'membershipEndDate' },
      { label: '멤버십 총기간', key: 'membershipTotalRange' },
      { label: '멤버십 번호', key: 'membershipNumber' },
      { label: '우편번호', key: 'postcode' },
      { label: '주소1', key: 'address' },
      { label: '주소2', key: 'detailAddress' },
      { label: '주소3', key: 'extraAddress' },
      { label: '가입일', key: 'createdAt' },
    ]);

    const userSubscriptionsByUserId = _.groupBy(userSubscriptionList, 'userId');
    const userMembershipsByUserId = _.groupBy(userMembershipList, 'userId');
    const userAddressByUserId = _.keyBy(userAddressList, 'userId');

    setCsvExportData(
      _.map(userListToDisplay, user => {
        const userSubscriptions = userSubscriptionsByUserId[user.id] || [];
        const userSubscription = _.first(
          _.filter(userSubscriptions, userSubscription => {
            return userSubscription.steppaySubscriptionStatus === 'ACTIVE';
          }),
        );
        const totalSubscriptionMonths = _.sum(
          _.map(userSubscriptions, userSubscription => {
            if (userSubscription.interval === 'YEAR') {
              return userSubscription.activeOrderCount * 12;
            }

            return userSubscription.activeOrderCount;
          }),
        );

        const userMemberships = userMembershipsByUserId[user.id] || [];

        const userAddress = userAddressByUserId[user.id];
        const isMembershipRemain =
          userSubscription &&
          moment.utc(userSubscription.endDateTime).isAfter(moment.utc());

        return {
          id: user.id,
          email: user.email,
          nickname: user.nickname,
          membershipType: isMembershipRemain
            ? userSubscription!!.interval
            : '-',
          membershipStartDate: isMembershipRemain
            ? moment
                .utc(userSubscription!!.startDateTime)
                .local()
                .format('yyyy-MM-DD')
            : '-',
          membershipEndDate: isMembershipRemain
            ? moment
                .utc(userSubscription!!.endDateTime)
                .local()
                .format('yyyy-MM-DD')
            : '-',
          membershipTotalRange:
            totalSubscriptionMonths > 0
              ? `${Math.floor(totalSubscriptionMonths / 12)}년 ${
                  totalSubscriptionMonths % 12
                }개월`
              : '-',
          membershipNumber:
            _.join(
              _.map(
                _.filter(userMemberships, userMembership => {
                  return userMembership.status === 1;
                }),
                userMembership => {
                  return userMembership.membershipNo;
                },
              ),
              ', ',
            ) || '-',
          createdAt: user.createdAt,
          postcode: userAddress?.postcode,
          address: userAddress?.address,
          detailAddress: userAddress?.detailAddress,
          extraAddress: userAddress?.extraAddress,
        };
      }),
    );

    setCsvExportFileName(
      `nellsroom-userlist-${type}-${moment()
        .local()
        .format('yyyyMMDDHHmmss')}.csv`,
    );
  }, [
    type,
    userListToDisplay,
    userSubscriptionList,
    userMembershipList,
    userAddressList,
  ]);

  const renderRow = (
    index: number,
    user: AdminUserItem,
    userSubscriptions?: AdminUserSubscriptionItem[],
    userMemberships?: AdminUserMembershipItem[],
    userAddress?: AdminUserAddressItem,
  ) => {
    const userSubscription = _.first(
      _.filter(userSubscriptions, userSubscription => {
        return userSubscription.steppaySubscriptionStatus === 'ACTIVE';
      }),
    );
    const totalSubscriptionMonths = _.sum(
      _.map(userSubscriptions, userSubscription => {
        if (userSubscription.interval === 'YEAR') {
          return userSubscription.activeOrderCount * 12;
        }

        return userSubscription.activeOrderCount;
      }),
    );
    return (
      <tr key={index}>
        <td>{user.id}</td>
        <td>{user.email}</td>
        <td>
          {user.profileImageUrl && (
            <Image
              src={user.profileImageUrl}
              width={24}
              roundedCircle
              className={'me-2'}
            />
          )}
          {user.nickname}
        </td>
        {userSubscription &&
        moment.utc(userSubscription.endDateTime).isAfter(moment.utc()) ? (
          <>
            <td>{userSubscription.interval}</td>
            <td>
              {moment
                .utc(userSubscription.startDateTime)
                .local()
                .format('yyyy-MM-DD')}
            </td>
            <td>
              {moment
                .utc(userSubscription.endDateTime)
                .local()
                .format('yyyy-MM-DD')}
            </td>
          </>
        ) : (
          <>
            <td>-</td>
            <td>-</td>
            <td>-</td>
          </>
        )}
        <td>
          {totalSubscriptionMonths > 0
            ? `${Math.floor(totalSubscriptionMonths / 12)}년 ${
                totalSubscriptionMonths % 12
              }개월`
            : '-'}
        </td>
        <td>
          {_.join(
            _.map(
              _.filter(userMemberships, userMembership => {
                return userMembership.status === 1;
              }),
              userMembership => {
                return userMembership.membershipNo;
              },
            ),
            ', ',
          ) || '-'}
        </td>
        {userAddress ? (
          <>
            <td>{userAddress.postcode}</td>
            <td>{userAddress.address}</td>
            <td>{userAddress.detailAddress}</td>
            <td>{userAddress.extraAddress}</td>
          </>
        ) : (
          <>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
          </>
        )}
        <td>{moment.utc(user.createdAt).local().format('yyyy-MM-DD')}</td>
      </tr>
    );
  };

  const renderContent = () => {
    if (!ready) {
      return (
        <div className={'my-5 py-5 text-center'}>
          <Spinner />
        </div>
      );
    }

    const userSubscriptionsByUserId = _.groupBy(userSubscriptionList, 'userId');
    const userAddressByUserId = _.keyBy(userAddressList, 'userId');
    const userMembershipsByUserId = _.groupBy(userMembershipList, 'userId');

    return (
      <>
        <div className={'mt-4'}>
          <Button
            className={'me-2'}
            variant={type === 'all' ? 'primary' : 'outline-primary'}
            onClick={() => setType('all')}
          >
            전체
          </Button>
          <Button
            className={'me-2'}
            variant={type === 'membership' ? 'primary' : 'outline-primary'}
            onClick={() => setType('membership')}
          >
            연간 + 월간 멤버십
          </Button>
          <Button
            className={'me-2'}
            variant={type === 'membershipYear' ? 'primary' : 'outline-primary'}
            onClick={() => setType('membershipYear')}
          >
            연간 멤버십
          </Button>
          <Button
            className={'me-2'}
            variant={type === 'membershipMonth' ? 'primary' : 'outline-primary'}
            onClick={() => setType('membershipMonth')}
          >
            월간 멤버십
          </Button>
        </div>
        <div className={'mt-4 text-end'}>
          <CSVLink
            data={csvExportData}
            headers={csvExportHeaders}
            className={'btn btn-success'}
            filename={csvExportFileName}
          >
            CSV 다운로드
          </CSVLink>
        </div>
        <Table striped bordered variant={'dark'} className={'mt-4 table-sm'}>
          <thead>
            <tr>
              <th>#</th>
              <th>이메일</th>
              <th>닉네임</th>
              <th>멤버십 종류</th>
              <th>멤버십 시작일</th>
              <th>멤버십 종료일</th>
              <th>멤버십 총기간</th>
              <th>멤버십 번호</th>
              <th>우편번호</th>
              <th>주소1</th>
              <th>주소2</th>
              <th>주소3</th>
              <th>가입일</th>
            </tr>
          </thead>
          <tbody>
            {_.reverse([...userListToDisplay]).map((user, index) =>
              renderRow(
                index,
                user,
                userSubscriptionsByUserId[user.id] || [],
                userMembershipsByUserId[user.id] || [],
                userAddressByUserId[user.id],
              ),
            )}
          </tbody>
        </Table>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>유저 관리</title>
      </Helmet>
      <AdminGnb />
      <Container className={'mt-4'}>
        <h3>유저 관리</h3>

        {renderContent()}
      </Container>
    </>
  );
};

export default AdminUserListPage;
