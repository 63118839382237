import { Route, Routes } from 'react-router-dom';
import LoginPage from '../pages/LoginPage';
import { HomePage } from '../pages/HomePage/Loadable';
import LogoutPage from '../pages/LogoutPage';
import { NotFoundPage } from '../components/NotFoundPage/Loadable';
import * as React from 'react';
import UserRoute from './UserRoute';
import AdminRoute from './AdminRoute';
import AdminHomePage from '../pages/AdminHomePage';
import AdminUserListPage from '../pages/AdminUserPage';
import { MembershipPage } from '../pages/MembershipPage';
import { MembershipResultPage } from '../pages/MembershipResultPage';
import PrivacyPolicyPage from '../pages/PrivacyPolicyPage';
import AgreementPage from '../pages/AgreementPage';
import AdminVideoListPage from '../pages/AdminVideoListPage';
import AdminVideoDetailPage from '../pages/AdminVideoDetailPage';
import NoticeListPage from '../pages/NoticeListPage';
import FaqListPage from '../pages/FaqListPage';
import MeWithdrawalPage from '../pages/MeWithdrawalPage';
import AdminLivePage from '../pages/AdminLivePage';
import AdminHighlightVideoListPage from '../pages/AdminHighlightVideoListPage';
import AdminHighlightVideoDetailPage from '../pages/AdminHighlightVideoDetailPage';
import AdminUserStoryListPage from '../pages/AdminUserStoryListPage';
import AdminEtcPage from '../pages/AdminEtcPage';
import AdminVideoCategoryListPage from '../pages/AdminVideoCategoryListPage';
import AdminVideoCategoryDetailPage from '../pages/AdminVideoCategoryDetailPage';
import AdminMessageRoomListPage from '../pages/AdminMessageRoomListPage';
import AdminMessageRoomDetailPage from '../pages/AdminMessageRoomDetailPage';
import AdminNoticeListPage from '../pages/AdminNoticeListPage';
import AdminNoticeDetailPage from '../pages/AdminNoticeDetailPage';
import NoticeDetailPage from '../pages/NoticeDetailPage';
import FaqDetailPage from '../pages/FaqDetailPage';
import AdminFaqDetailPage from '../pages/AdminFaqDetailPage';
import AdminFaqListPage from '../pages/AdminFaqListPage';
import AdminMessageSendPage from '../pages/AdminMessageSendPage';
import AdminPushSendPage from '../pages/AdminPushSendPage';
import MeMembershipPage from '../pages/MeMembership';
import FarewellPage from '../pages/FarewellPage';
import MeMembershipAddressPage from '../pages/MeMembershipAddressPage';
import AdminMessagePage from '../pages/AdminMessagePage';
import { VideoDetailPage } from '../pages/VideoDetailPage';
import AdminVideoCommentListPage from '../pages/AdminVideoCommentListPage';

const Router = () => {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/about/privacy" element={<PrivacyPolicyPage />} />
      <Route path="/about/agreement" element={<AgreementPage />} />
      <Route path="/notices" element={<NoticeListPage />} />
      <Route path="/notices/:noticeId" element={<NoticeDetailPage />} />
      <Route path="/faqs" element={<FaqListPage />} />
      <Route path="/faqs/:faqId" element={<FaqDetailPage />} />
      <Route path="/farewell" element={<FarewellPage />} />
      <Route element={<UserRoute />}>
        <Route path="/" element={<HomePage />} />
        <Route path="/videos/:videoId" element={<VideoDetailPage />} />
        <Route path="/membership" element={<MembershipPage />} />
        <Route path="/membership/result" element={<MembershipResultPage />} />
        <Route path="/logout" element={<LogoutPage />} />
        <Route path="/me/membership" element={<MeMembershipPage />} />
        <Route
          path="/me/membership/address"
          element={<MeMembershipAddressPage />}
        />
        <Route path="/me/withdrawal" element={<MeWithdrawalPage />} />
      </Route>
      <Route element={<AdminRoute />}>
        <Route path="/admin" element={<AdminHomePage />} />
        <Route path="/admin/users" element={<AdminUserListPage />} />
        <Route path="/admin/videos" element={<AdminVideoListPage />} />
        <Route
          path="/admin/video-comments"
          element={<AdminVideoCommentListPage />}
        />
        <Route
          path="/admin/video-categories"
          element={<AdminVideoCategoryListPage />}
        />
        <Route
          path="/admin/video-categories/:videoCategoryId"
          element={<AdminVideoCategoryDetailPage />}
        />
        <Route
          path="/admin/video-categories/new"
          element={<AdminVideoCategoryDetailPage />}
        />
        <Route path="/admin/videos/new" element={<AdminVideoDetailPage />} />
        <Route
          path="/admin/videos/:videoId"
          element={<AdminVideoDetailPage />}
        />
        <Route
          path="/admin/highlight-videos"
          element={<AdminHighlightVideoListPage />}
        />
        <Route
          path="/admin/highlight-videos/new"
          element={<AdminHighlightVideoDetailPage />}
        />
        <Route
          path="/admin/highlight-videos/:highlightVideoId"
          element={<AdminHighlightVideoDetailPage />}
        />
        <Route path="/admin/live" element={<AdminLivePage />} />
        <Route path="/admin/messages" element={<AdminMessagePage />} />
        <Route path="/admin/messages/send" element={<AdminMessageSendPage />} />
        <Route
          path="/admin/message-rooms"
          element={<AdminMessageRoomListPage />}
        />
        <Route
          path="/admin/message-rooms/:messageRoomId"
          element={<AdminMessageRoomDetailPage />}
        />
        <Route
          path="/admin/user-stories"
          element={<AdminUserStoryListPage />}
        />
        <Route path="/admin/notices" element={<AdminNoticeListPage />} />
        <Route path="/admin/notices/new" element={<AdminNoticeDetailPage />} />
        <Route
          path="/admin/notices/:noticeId"
          element={<AdminNoticeDetailPage />}
        />
        <Route path="/admin/faqs" element={<AdminFaqListPage />} />
        <Route path="/admin/faqs/new" element={<AdminFaqDetailPage />} />
        <Route path="/admin/faqs/:faqId" element={<AdminFaqDetailPage />} />
        <Route
          path="/admin/notifications/send"
          element={<AdminPushSendPage />}
        />
        <Route path="/admin/etc" element={<AdminEtcPage />} />
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default Router;
