import { Helmet } from 'react-helmet-async';
import AdminGnb from '../../components/AdminGnb';
import {
  Col,
  Container,
  Image,
  ListGroup,
  Row,
  Spinner,
} from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { AdminMessageRoomItem } from '../../api/adminMessageRoomApi/type';
import adminMessageRoomApi from '../../api/adminMessageRoomApi';
import { useParams } from 'react-router-dom';
import {
  AdminMessageItem,
  MessageAttachment,
  MessageAttachmentImage,
  MessageAttachmentVideo,
} from '../../api/adminMessageApi/type';
import _ from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';

const AdminMessageRoomDetailPage = () => {
  const params = useParams();

  const [ready, setReady] = useState(false);
  const [messageRoom, setMessageRoom] = useState<AdminMessageRoomItem>();

  const [busy, setBusy] = useState(false);
  const [messages, setMessages] = useState<AdminMessageItem[]>([]);
  const [hasNext, setHasNext] = useState(false);

  const requestMessages = async () => {
    if (!messageRoom) {
      return;
    }

    if (busy) {
      return;
    }

    setBusy(true);

    try {
      const res = await adminMessageRoomApi.getMessages(
        messageRoom.id,
        messages.length > 0 ? _.last(messages)!!.id : null,
      );
      setMessages(oldArray => [...oldArray, ...res.data.items]);
      setHasNext(res.data.hasNext);
    } catch {}

    setBusy(false);
  };

  const renderArtistMessage = (message: AdminMessageItem) => {
    const { text, attachments, createdAt } = message;
    let attachmentVideo: MessageAttachmentVideo | null = null;
    let attachmentImage: MessageAttachmentImage | null = null;
    if (attachments) {
      _.forEach(JSON.parse(attachments) as MessageAttachment[], attachment => {
        if (attachment.type === 'video') {
          attachmentVideo = attachment as MessageAttachmentVideo;
        } else if (attachment.type === 'image') {
          attachmentImage = attachment as MessageAttachmentImage;
        }
      });
    }

    return (
      <div className={'d-flex my-1 justify-content-end'}>
        <div
          style={{
            color: '#222230',
            marginLeft: 80,
            position: 'relative',
          }}
        >
          {attachmentVideo !== null && (
            <a
              href={(attachmentVideo as MessageAttachmentVideo).videoUrl}
              target="_blank"
              rel="noreferrer"
            >
              <div style={{ position: 'relative' }}>
                <Image
                  src={
                    (attachmentVideo as MessageAttachmentVideo)
                      .thumbnailImageUrl
                  }
                  width={200}
                  style={{ borderRadius: 8 }}
                />
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    zIndex: 1,
                    background: 'black',
                    borderRadius: 8,
                    opacity: 0.3,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                ></div>
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    zIndex: 2,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <FontAwesomeIcon
                    icon={faPlayCircle}
                    fontSize={40}
                    color={'white'}
                  />
                </div>
              </div>
            </a>
          )}
          {attachmentImage !== null && (
            <a
              href={(attachmentImage as MessageAttachmentImage).imageUrl}
              target="_blank"
              rel="noreferrer"
            >
              <Image
                src={(attachmentImage as MessageAttachmentImage).imageUrl}
                width={200}
                style={{ borderRadius: 8 }}
              />
            </a>
          )}
          {text && (
            <div
              style={{
                fontSize: 14,
                lineHeight: 1.2,
                padding: 8,
                backgroundColor: '#FFE7E2',
                borderRadius: 4,
              }}
            >
              <span style={{ wordBreak: 'break-all' }}>{text}</span>
            </div>
          )}
          <div
            className={'d-inline-block text-end'}
            style={{
              position: 'absolute',
              right: '100%',
              bottom: 0,
              paddingRight: 8,
              width: 80,
            }}
          >
            <span style={{ fontSize: 12 }}>
              {moment.utc(createdAt).local().format('LT')}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderUserMessage = (
    message: AdminMessageItem,
    prevMessage?: AdminMessageItem,
  ) => {
    const { sender, text, attachments, createdAt } = message;
    return (
      <div className={'d-flex my-2'}>
        {sender.profileImageUrl && (
          <div style={{ width: 50, position: 'relative' }}>
            {(!prevMessage ||
              message.sender.id !== prevMessage.sender.id ||
              !moment
                .utc(message.createdAt)
                .local()
                .startOf('day')
                .isSame(
                  moment.utc(prevMessage.createdAt).local().startOf('day'),
                )) && (
              <Image
                src={sender.profileImageUrl}
                width={40}
                height={40}
                roundedCircle
                style={{ position: 'absolute', top: 0, left: 0 }}
              />
            )}
          </div>
        )}
        <div
          style={{
            color: '#222230',
            fontSize: 14,
            lineHeight: 1.2,
            padding: 8,
            backgroundColor: '#E2F1FF',
            borderRadius: 4,
            position: 'relative',
            marginRight: 80,
          }}
        >
          <span style={{ wordBreak: 'break-all' }}>{text}</span>
          <div
            className={'d-inline-block'}
            style={{
              position: 'absolute',
              left: '100%',
              bottom: 0,
              paddingLeft: 8,
              width: 80,
            }}
          >
            <span style={{ fontSize: 12 }}>
              {moment.utc(createdAt).local().format('LT')}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderDay = (
    message: AdminMessageItem,
    prevMessage?: AdminMessageItem,
  ) => {
    if (
      prevMessage &&
      moment
        .utc(message.createdAt)
        .local()
        .startOf('day')
        .isSame(moment.utc(prevMessage.createdAt).local().startOf('day'))
    ) {
      return null;
    }

    return (
      <div
        style={{
          paddingTop: 20,
          paddingBottom: 20,
          color: 'black',
          fontSize: 12,
          textAlign: 'center',
        }}
      >
        {moment.utc(message.createdAt).local().format('ll')}
      </div>
    );
  };

  const renderItems = () => {
    return _.map(messages, (message, index) => {
      const prevMessage = messages[index + 1];

      const { sender } = message;
      const isArtistMessage = sender.id === 0;

      return (
        <div key={index.toString()}>
          {renderDay(message, prevMessage)}
          {isArtistMessage
            ? renderArtistMessage(message)
            : renderUserMessage(message, prevMessage)}
        </div>
      );
    });
  };

  const renderContent = () => {
    return (
      <Row className={'justify-content-center'}>
        <Col md={8} xl={6} xxl={4}>
          <div
            id="scrollableDiv"
            style={{
              height: 700,
              overflowY: 'auto',
              display: 'flex',
              flexDirection: 'column-reverse',
              backgroundColor: 'white',
              borderRadius: 10,
              padding: 16,
            }}
          >
            <InfiniteScroll
              dataLength={messages.length}
              next={requestMessages}
              hasMore={hasNext}
              loader={
                <ListGroup.Item className={'text-center'}>
                  <Spinner size={'sm'} />
                </ListGroup.Item>
              }
              style={{ display: 'flex', flexDirection: 'column-reverse' }}
              inverse={true}
              scrollableTarget="scrollableDiv"
            >
              {renderItems()}
            </InfiniteScroll>
          </div>
        </Col>
      </Row>
    );
  };

  const init = async () => {
    const messageRoomId = params.messageRoomId
      ? parseInt(params.messageRoomId, 10)
      : undefined;

    if (!messageRoomId) {
      return;
    }

    const res = await adminMessageRoomApi.findById(messageRoomId);
    setMessageRoom(res.data);
    setReady(true);
  };

  useEffect(() => {
    if (!messageRoom) {
      return;
    }

    adminMessageRoomApi.read(messageRoom.id);
    requestMessages();
  }, [messageRoom]);

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Helmet>
        <title>{`${messageRoom?.user.nickname}님과의 대화`}</title>
      </Helmet>
      <AdminGnb />
      <Container>
        {ready ? (
          <>
            <div className={'mt-4 d-flex align-items-center'}>
              {messageRoom && (
                <Image
                  src={messageRoom.user.profileImageUrl!!}
                  width={40}
                  roundedCircle
                  className={'me-2'}
                />
              )}
              <h3
                className={'d-inline-block mb-0'}
              >{`${messageRoom?.user.nickname}님과의 대화`}</h3>
            </div>
            <div className={'mt-4'}>{renderContent()}</div>
          </>
        ) : (
          <div className={'my-5 py-5 text-center'}>
            <Spinner />
          </div>
        )}
      </Container>
    </>
  );
};

export default AdminMessageRoomDetailPage;
