import { Helmet } from 'react-helmet-async';
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import React, { createRef, useCallback, useEffect, useState } from 'react';
import Gnb from '../../components/Gnb';
import DaumPostcodeEmbed from 'react-daum-postcode';
import _ from 'lodash';
import meApi from '../../api/meApi';

const MeMembershipAddressPage = () => {
  const [ready, setReady] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [busy, setBusy] = useState(false);

  const [isDaumPostcodeVisible, setIsDaumPostcodeVisible] = useState(false);
  const [postcode, setPostcode] = useState('');
  const [address, setAddress] = useState('');
  const [detailAddress, setDetailAddress] = useState('');
  const [extraAddress, setExtraAddress] = useState('');

  useEffect(() => {
    (async () => {
      const res = await meApi.getAddress();
      setPostcode(res.data.postcode || '');
      setAddress(res.data.address || '');
      setDetailAddress(res.data.detailAddress || '');
      setExtraAddress(res.data.extraAddress || '');
      setReady(true);
    })();
  }, []);

  const handleComplete = useCallback(data => {
    var addr = ''; // 주소 변수
    var extraAddr = ''; // 참고항목 변수

    //사용자가 선택한 주소 타입에 따라 해당 주소 값을 가져온다.
    if (data.userSelectedType === 'R') {
      // 사용자가 도로명 주소를 선택했을 경우
      addr = data.roadAddress;
    } else {
      // 사용자가 지번 주소를 선택했을 경우(J)
      addr = data.jibunAddress;
    }

    // 사용자가 선택한 주소가 도로명 타입일때 참고항목을 조합한다.
    if (data.userSelectedType === 'R') {
      // 법정동명이 있을 경우 추가한다. (법정리는 제외)
      // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
      if (data.bname !== '' && /[동|로|가]$/g.test(data.bname)) {
        extraAddr += data.bname;
      }
      // 건물명이 있고, 공동주택일 경우 추가한다.
      if (data.buildingName !== '' && data.apartment === 'Y') {
        extraAddr +=
          extraAddr !== '' ? ', ' + data.buildingName : data.buildingName;
      }
      // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
      if (extraAddr !== '') {
        extraAddr = ' (' + extraAddr + ')';
      }
      setExtraAddress(extraAddr);
    } else {
      setExtraAddress('');
    }

    setAddress(addr);
    setPostcode(data.zonecode);

    setIsDaumPostcodeVisible(false);
  }, []);

  const onSubmit = useCallback(
    async e => {
      e.preventDefault();

      if (busy) {
        return;
      }

      if (_.isEmpty(postcode) || _.isEmpty(address)) {
        alert('우편번호 찾기를 입력하여 주소를 입력하세요.');
        return;
      }

      if (_.isEmpty(_.trim(detailAddress))) {
        alert('상세 주소를 정확히 입력하세요.');
        return;
      }

      setBusy(true);

      try {
        await meApi.updateAddress(
          postcode,
          address,
          detailAddress,
          extraAddress,
        );
        window.location.reload();
      } catch (_) {}

      setBusy(false);
    },
    [busy, postcode, address, detailAddress, extraAddress],
  );

  const ref = createRef<DaumPostcodeEmbed>();

  const renderContent = () => {
    if (!ready) {
      return (
        <div className={'my-5 py-5 text-center'}>
          <Spinner />
        </div>
      );
    }

    if (!isEditMode) {
      return (
        <div>
          {_.isEmpty(postcode) ? (
            <h5>설정된 주소가 없습니다</h5>
          ) : (
            <>
              <h5>현재 설정된 배송지</h5>
              <div className={'mt-3'}>
                [{postcode}] {address} {detailAddress} {extraAddress}
              </div>
            </>
          )}

          <div className={'mt-4'}>
            <a
              href="#"
              onClick={e => {
                e.preventDefault();

                setIsEditMode(true);
              }}
              className={
                'd-flex w-100 text-decoration-none text-black justify-content-center align-items-center'
              }
              style={{
                backgroundColor: '#FED86C',
                height: 40,
                borderRadius: 20,
                fontSize: 16,
                fontWeight: '700',
              }}
            >
              {_.isEmpty(postcode) ? '배송지 등록하기' : '배송지 수정하기'}
            </a>
          </div>
        </div>
      );
    }

    return (
      <>
        <Form onSubmit={onSubmit}>
          <Form.Group as={Row} className="mb-3" controlId="postcode">
            <Form.Label>우편번호</Form.Label>
            <Col xs={6} sm={4} md={3} lg={3}>
              <Form.Control
                name={'postcode'}
                placeholder={'우편번호'}
                value={postcode}
                onChange={e => setPostcode(e.currentTarget.value)}
                disabled
                required
              />
            </Col>
            <Col xs={6} sm={4} md={3} lg={3}>
              <Button
                onClick={e => {
                  e.preventDefault();
                  setIsDaumPostcodeVisible(v => {
                    const newV = !v;
                    if (newV) {
                      ref.current?.render();
                    }
                    return newV;
                  });
                }}
                variant={'outline-warning'}
                className={'w-100'}
              >
                우편번호 찾기
              </Button>
            </Col>
          </Form.Group>

          <div style={{ position: 'relative' }}>
            <div
              className={isDaumPostcodeVisible ? '' : 'd-none'}
              style={{ position: 'absolute', top: 0, left: 0, right: 0 }}
            >
              <DaumPostcodeEmbed
                ref={ref}
                onComplete={handleComplete}
                autoClose={false}
              />
            </div>
            <Form.Group className="mb-2" controlId="address">
              <Form.Label>주소</Form.Label>
              <Form.Control
                name={'address'}
                placeholder={'주소'}
                value={address}
                onChange={e => setAddress(e.currentTarget.value)}
                disabled
                required
              />
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="address2">
              <Col sm={8} className={'mb-2 mb-sm-0'}>
                <Form.Control
                  name={'address2'}
                  placeholder={'상세주소'}
                  value={detailAddress}
                  onChange={e => setDetailAddress(e.currentTarget.value)}
                  disabled={busy}
                  required
                />
              </Col>
              <Col sm={4}>
                <Form.Control
                  name={'detailAddress'}
                  placeholder={'참고항목'}
                  value={extraAddress}
                  onChange={e => setExtraAddress(e.currentTarget.value)}
                  disabled
                  required
                />
              </Col>
            </Form.Group>
          </div>

          <Form.Group as={Row} className={'mt-4'}>
            <Col>
              <Button
                type={'submit'}
                className={
                  'd-flex w-100 text-decoration-none text-black justify-content-center align-items-center mt-3 border-0'
                }
                style={{
                  backgroundColor: '#FED86C',
                  height: 40,
                  borderRadius: 20,
                  fontSize: 16,
                  fontWeight: '700',
                }}
                disabled={busy}
              >
                저장하기
              </Button>
            </Col>
          </Form.Group>
        </Form>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>배송지 관리</title>
      </Helmet>
      <Gnb />
      <Container className={'d-flex flex-column h-100'}>
        <h3 className={'mt-4'}>배송지 관리</h3>
        <div className={'mt-4'}>{renderContent()}</div>
      </Container>
    </>
  );
};

export default MeMembershipAddressPage;
