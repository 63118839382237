import userApi from './userApi';
import apiClient from './index';
import AuthService from '../../services/authService';

let isInitialized = false;
const init = () => {
  if (isInitialized) {
    console.log('axios already initialized');
    return;
  }

  apiClient.interceptors.request.use(
    async config => {
      console.log(`request api: ${config.url}`);

      const accessToken = AuthService.getAccessToken();
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }

      const appPlatform = localStorage.getItem('x-nellsroom-platform');
      const appVersion = localStorage.getItem('x-nellsroom-version');

      if (appPlatform) {
        config.headers['x-nellsroom-platform'] = appPlatform;
      }
      if (appVersion) {
        config.headers['x-nellsroom-version'] = appVersion;
      }

      return config;
    },
    err => Promise.reject(err),
  );

  let lock = false;
  let subscribers: ((token: string) => void)[] = [];

  function subscribeTokenRefresh(cb: (token: string) => void) {
    subscribers.push(cb);
  }

  function onRefreshed(token: string) {
    subscribers.forEach(cb => cb(token));
  }

  const getRefreshedAccessToken = async (
    refreshToken: string,
  ): Promise<string | void> => {
    try {
      console.log('try to refresh access token');
      const res = await userApi.tokenRefresh(refreshToken);
      console.log('refresh succeeded');

      AuthService.setAccessToken(res.data.accessToken);
      AuthService.setRefreshToken(res.data.refreshToken);

      lock = false;
      onRefreshed(res.data.accessToken);
      subscribers = [];

      return res.data.accessToken;
    } catch (e) {
      lock = false;
      subscribers = [];

      console.log('removeAccessToken');
      if (AuthService.getAccessToken()) {
        AuthService.setExpiredAccessToken(AuthService.getAccessToken());
      }

      if (AuthService.getRefreshToken()) {
        AuthService.setExpiredRefreshToken(AuthService.getRefreshToken());
      }
      AuthService.setAccessToken(null);
      AuthService.setRefreshToken(null);
    }
  };

  const createAlertForError = (
    err: any,
    message: string | undefined = undefined,
  ) => {
    if (typeof message === 'undefined' || message === '') {
      message = '일시적인 오류가 발생했습니다.';
    }

    return new Promise((resolve, reject) => {
      alert(message);
      reject(err);
      // Alert.alert(message!, undefined, [
      //   {
      //     text: '확인',
      //     onPress: () => {
      //       reject(err);
      //     },
      //   },
      // ]);
    });
  };

  const createAlertForRetry = (
    err: any,
    message: string | undefined = undefined,
  ) => {
    if (typeof message === 'undefined' || message === '') {
      message = '서버에 일시적인 오류가 발생했습니다. 다시 시도하시겠습니까?';
    }

    return new Promise((resolve, reject) => {
      if (window.confirm(message)) {
        resolve(apiClient(err.config));
        return;
      }
      reject(err);
    });
  };

  apiClient.interceptors.response.use(
    res => res,
    async err => {
      if (err.config.customErrorHandling === true) {
        return Promise.reject(err);
      }

      if (
        typeof err.response === 'undefined' ||
        typeof err.response.status !== 'number'
      ) {
        return createAlertForRetry(err);
      }

      const {
        config,
        response: { status, data },
      } = err;
      const originalRequest = config;

      console.log('axios error', {
        status,
        data,
      });

      if (
        status === 401 &&
        config.url !== '/users/v1/sign-in' &&
        config.url !== '/users/v1/sign-up' &&
        config.url !== '/users/v1/sign-up/email/send' &&
        config.url !== '/users/v1/sign-up/email/verify'
      ) {
        if (config.url !== '/users/v1/token/refresh') {
          if (lock) {
            return new Promise(resolve => {
              subscribeTokenRefresh((token: string) => {
                originalRequest.headers.Authorization = `Bearer ${token}`;
                resolve(apiClient(originalRequest));
              });
            });
          }
          lock = true;

          const refreshToken = AuthService.getRefreshToken();
          if (refreshToken) {
            const accessToken = await getRefreshedAccessToken(refreshToken);

            if (typeof accessToken === 'string') {
              config.headers.Authorization = `Bearer ${accessToken}`;
              return apiClient(config);
            }
          }
        }

        if (AuthService.getAccessToken()) {
          AuthService.setExpiredAccessToken(AuthService.getAccessToken());
        }
        if (AuthService.getRefreshToken()) {
          AuthService.setExpiredRefreshToken(AuthService.getRefreshToken());
        }
        AuthService.setAccessToken(null);
        AuthService.setRefreshToken(null);

        window.location.reload();

        return Promise.reject(err);
      }

      if (status >= 500 && status < 600) {
        return createAlertForRetry(err);
      }

      return createAlertForError(err, data.message);
    },
  );

  isInitialized = true;
  console.log('axios initialized');
};

export default init;
