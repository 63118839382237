import { Helmet } from 'react-helmet-async';
import AdminGnb from '../../components/AdminGnb';
import {
  Badge,
  Button,
  Col,
  Container,
  Image,
  ListGroup,
  Row,
} from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import { AdminMessageRoomItem } from '../../api/adminMessageRoomApi/type';
import adminMessageRoomApi from '../../api/adminMessageRoomApi';

const AdminMessageRoomListPage = () => {
  const [messageRooms, setMessageRooms] = useState<AdminMessageRoomItem[]>([]);
  const [hasNext, setHasNext] = useState(false);
  const [nextSkip, setNextSkip] = useState(0);
  const [busy, setBusy] = useState(false);

  const fetchData = async () => {
    if (busy) {
      return;
    }

    setBusy(true);

    try {
      const res = await adminMessageRoomApi.getLatest(nextSkip);
      setMessageRooms(oldArray => {
        const oldItemById = _.keyBy(oldArray, 'id');
        const newArray = [
          ...oldArray,
          ..._.filter(res.data.items, item => {
            return !(item.id in oldItemById);
          }),
        ];
        setNextSkip(newArray.length);
        return newArray;
      });
      setHasNext(res.data.hasNext);
    } catch {
      setHasNext(false);
    }

    setBusy(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const renderItems = () => {
    return (
      <ListGroup>
        {_.map(messageRooms, (messageRoom, index) => {
          const { user, lastMessage } = messageRoom;
          return (
            <ListGroup.Item
              action
              href={`/admin/message-rooms/${messageRoom.id}`}
              key={messageRoom.id.toString()}
              className={'d-flex align-items-center'}
            >
              <div className={'me-2'} style={{ width: 40 }}>
                {user.profileImageUrl && (
                  <Image src={user.profileImageUrl} width={40} roundedCircle />
                )}
              </div>
              <div
                className={'d-inline-block flex-grow-1'}
                style={{ overflow: 'auto' }}
              >
                <h5 className={'mb-0'}>{user.nickname}</h5>
                <div
                  style={{
                    whiteSpace: 'nowrap',
                    wordBreak: 'break-all',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  {lastMessage.text}
                </div>
              </div>
              <div className={'d-inline-block text-end'} style={{ width: 70 }}>
                <div className={'small'}>
                  {moment
                    .utc(lastMessage.createdAt)
                    .local()
                    .format(
                      moment(lastMessage.createdAt).isBefore(
                        moment().startOf('day'),
                      )
                        ? 'MMM Do'
                        : 'HH:mm',
                    )}
                </div>
                <Badge
                  bg={'danger'}
                  style={
                    messageRoom.artistUnreadCount === 0
                      ? { visibility: 'hidden' }
                      : {}
                  }
                >
                  {messageRoom.artistUnreadCount}
                </Badge>
              </div>
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    );
  };

  const renderContent = () => {
    return (
      <Row className={'justify-content-center'}>
        <Col
          md={8}
          xl={6}
          xxl={4}
          id="scrollableDiv"
          style={{
            height: 700,
            overflowY: 'auto',
          }}
        >
          <InfiniteScroll
            dataLength={messageRooms.length}
            next={fetchData}
            hasMore={hasNext}
            loader={<h4>Loading...</h4>}
            scrollableTarget="scrollableDiv"
          >
            {renderItems()}
          </InfiniteScroll>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <Helmet>
        <title>메신저</title>
      </Helmet>
      <AdminGnb />
      <Container>
        <div className={'mt-4 text-end'}>
          <Button
            href="/admin/user-stories"
            variant={'success'}
            className={'me-2'}
          >
            망토라디오 사연 목록
          </Button>
          <Button href="/admin/messages/send" variant={'success'}>
            메신저 발송하기
          </Button>
        </div>
        <div className={'mt-4'}>{renderContent()}</div>
      </Container>
    </>
  );
};

export default AdminMessageRoomListPage;
