import apiClient from '../index';
import { AdminUserListResponse } from './type';

const adminUserApi = {
  getUsers: async () => {
    return await apiClient.get<AdminUserListResponse>('/admin/users');
  },
};

export default adminUserApi;
