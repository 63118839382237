import { Button, Col, Form, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import adminAppConfigApi from '../../api/adminAppConfigApi';

const MessageRateLimit = () => {
  const [ready, setReady] = useState(false);
  const [busy, setBusy] = useState(false);
  const [limitLength, setLimitLength] = useState(0);
  const [limitCount, setLimitCount] = useState(0);

  const requestGetMessageRateLimit = async () => {
    try {
      const res = await adminAppConfigApi.getConfigByKey('MessageRateLimit', {
        customErrorHandling: true,
      });
      if (res.data.value) {
        const json = JSON.parse(res.data.value);
        setLimitLength(json.limitLength);
        setLimitCount(json.limitCount);
      }
    } catch (_) {}
  };

  const onSubmit = async e => {
    e.preventDefault();

    if (busy) {
      return;
    }

    setBusy(true);
    try {
      await adminAppConfigApi.updateConfigByKeyAndValue(
        'MessageRateLimit',
        JSON.stringify({ limitCount, limitLength }),
      );
      alert('적용되었습니다.');
      await requestGetMessageRateLimit();
    } catch (_) {}
    setBusy(false);
  };

  useEffect(() => {
    (async () => {
      await requestGetMessageRateLimit();
      setReady(true);
    })();
  }, []);

  return (
    <>
      <Form onSubmit={onSubmit}>
        <Form.Group as={Row} className="mb-3" controlId="title">
          <Form.Label column sm={2}>
            메시지 글자 수 제한
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="number"
              name={'limitLength'}
              placeholder={'0'}
              value={limitLength}
              onChange={e => setLimitLength(parseInt(e.currentTarget.value))}
              required
              disabled={busy}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="title">
          <Form.Label column sm={2}>
            연속 메시지 제한
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="number"
              name={'limitCount'}
              placeholder={'3'}
              value={limitCount}
              onChange={e => setLimitCount(parseInt(e.currentTarget.value))}
              required
              disabled={busy}
            />
          </Col>
        </Form.Group>

        <div className={'text-end mt-3'}>
          <Button type={'submit'} disabled={!ready || busy}>
            저장
          </Button>
        </div>
      </Form>
    </>
  );
};

export default MessageRateLimit;
