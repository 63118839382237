import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Button, Col, Container, Nav, Row, Table } from 'react-bootstrap';
import AdminGnb from '../../components/AdminGnb';
import { AdminNoticeItem } from '../../api/adminNoticeApi/type';
import adminNoticeApi from '../../api/adminNoticeApi';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';

const AdminNoticeListPage = () => {
  const [notices, setNotices] = useState<AdminNoticeItem[]>([]);
  const [hasPrev, setHasPrev] = useState(false);
  const [hasNext, setHasNext] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [searchParams] = useSearchParams();

  const requestNotices = async (page: number) => {
    const res = await adminNoticeApi.getNotices(page);
    setNotices(res.data.items);
    setHasNext(res.data.hasNext);
  };

  useEffect(() => {
    const page = searchParams.get('page')
      ? parseInt(searchParams.get('page')!!, 10)
      : 1;
    setCurrentPage(page);
    setHasPrev(page > 1);

    (async () => {
      await requestNotices(page);
    })();
  }, []);

  const renderRow = useCallback((notice: AdminNoticeItem, index: number) => {
    return (
      <tr key={index.toString()}>
        <td>{notice.id}</td>
        <td>
          {notice.status === 0 && (
            <span className={'text-danger me-2'}>[삭제됨]</span>
          )}
          <a href={`/admin/notices/${notice.id}`} className={'text-white'}>
            {notice.title}
          </a>
        </td>
        <td>
          {moment.utc(notice.createdAt).local().format('yyyy-MM-DD hh:mm:ss')}
        </td>
        <td>
          <Button
            href={`/notices/${notice.id}`}
            size={'sm'}
            target={'_blank'}
            variant={'success'}
          >
            보기
          </Button>
        </td>
      </tr>
    );
  }, []);

  const renderContent = () => {
    return (
      <>
        <Table striped bordered variant={'dark'}>
          <thead>
            <tr>
              <th>#</th>
              <th>제목</th>
              <th>등록일</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {notices.map((highlightVideo, index) =>
              renderRow(highlightVideo, index),
            )}
          </tbody>
        </Table>

        <Row>
          {(hasPrev || hasNext) && (
            <>
              <Nav aria-label="Page navigation example" as={Col} md={'auto'}>
                <ul className="pagination">
                  {hasPrev && (
                    <li className="page-item">
                      <a
                        className="page-link"
                        href={`/admin/notices?page=${currentPage - 1}`}
                      >
                        이전
                      </a>
                    </li>
                  )}
                  {hasNext && (
                    <li className="page-item">
                      <a
                        className="page-link"
                        href={`/admin/notices?page=${currentPage + 1}`}
                      >
                        다음
                      </a>
                    </li>
                  )}
                </ul>
              </Nav>
            </>
          )}
          <Col />
          <Col md={'auto'}>
            <a href={'/admin/notices/new'} className={'btn btn-primary'}>
              새 공지사항 등록
            </a>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>공지사항 관리</title>
      </Helmet>
      <AdminGnb />
      <Container>
        <h3 className={'mt-4'}>공지사항 관리</h3>
        <div className={'mt-4'}>{renderContent()}</div>
      </Container>
    </>
  );
};

export default AdminNoticeListPage;
