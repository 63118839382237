import React from 'react';
import { NotFoundPage } from '../components/NotFoundPage';
import { Outlet } from 'react-router-dom';
import User from '../../types/User';
import AuthService from '../../services/authService';

const AdminRoute = (): React.ReactElement | null => {
  if (AuthService.getAccessToken() === null) {
    return <NotFoundPage />;
  }

  const rawUser = localStorage.getItem('user');
  if (rawUser === null) {
    return <NotFoundPage />;
  }

  const user: User = JSON.parse(rawUser);
  if (user.role !== 'Admin') {
    return <NotFoundPage />;
  }

  return <Outlet />;
};

export default AdminRoute;
