import apiClient from '../index';

const AdminVimeoApi = {
  getVideoDetail: async (videoId: string) => {
    return apiClient.get<Map<string, any>>(`/admin/vimeo/videos/${videoId}`);
  },
  getLiveEventInfo: async (liveEventId: string) => {
    return apiClient.get<Map<string, any>>(
      `/admin/vimeo/live-events/${liveEventId}`,
    );
  },
};

export default AdminVimeoApi;
