import apiClient from '../index';
import {
  MeAddressResponse,
  MeAddressUpdateResponse,
  MeWithdrawalResponse,
} from './type';

const MeApi = {
  getAddress: async () => {
    return await apiClient.get<MeAddressResponse>('/me/v1/address');
  },
  updateAddress: async (
    postcode: string,
    address: string,
    detailAddress: string,
    extraAddress: string,
  ) => {
    return await apiClient.put<MeAddressUpdateResponse>('/me/v1/address', {
      postcode,
      address,
      detailAddress,
      extraAddress,
    });
  },
  withdrawal: async () => {
    return await apiClient.post<MeWithdrawalResponse>('/me/withdrawal');
  },
};

export default MeApi;
