import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import adminAppConfigApi from '../../api/adminAppConfigApi';

const MembershipSubscriptionType = () => {
  const [ready, setReady] = useState(false);
  const [busy, setBusy] = useState(false);

  const [yearAllow, setYearAllow] = useState<boolean | null>(false);
  const [yearProductId, setYearProductId] = useState<string | null>();
  const [yearPriceId, setYearPriceId] = useState<string | null>();
  const [monthAllow, setMonthAllow] = useState<boolean | null>(false);
  const [monthProductId, setMonthProductId] = useState<string | null>();
  const [monthPriceId, setMonthPriceId] = useState<string | null>();

  const requestGetAppConfig = async () => {
    try {
      const res = await adminAppConfigApi.getConfigByKey(
        'MembershipSubscriptionSetting',
        { customErrorHandling: true },
      );

      if (res.data.value) {
        const json = JSON.parse(res.data.value);
        setYearAllow(json['yearAllow'] || false);
        setYearProductId(json['yearProductId'] || null);
        setYearPriceId(json['yearPriceId'] || null);
        setMonthAllow(json['monthAllow'] || false);
        setMonthProductId(json['monthProductId'] || null);
        setMonthPriceId(json['monthPriceId'] || null);
      }
    } catch (_) {}
  };

  const onSubmit = async e => {
    e.preventDefault();

    if (busy) {
      return;
    }

    setBusy(true);

    try {
      await adminAppConfigApi.updateConfigByKeyAndValue(
        'MembershipSubscriptionSetting',
        JSON.stringify({
          yearAllow,
          yearProductId,
          yearPriceId,
          monthAllow,
          monthProductId,
          monthPriceId,
        }),
      );
      alert('적용되었습니다.');
      await requestGetAppConfig();
    } catch (_) {}

    setBusy(false);
  };

  useEffect(() => {
    (async () => {
      await requestGetAppConfig();
    })();
    setReady(true);
  }, []);

  if (!ready) {
    return (
      <div className={'my-5 py-5 text-center'}>
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <Form onSubmit={onSubmit}>
        <h4 className={'mb-3'}>연간 멤버십</h4>
        <Form.Group as={Row} className="mb-3" controlId="yearAllow">
          <Form.Label column sm={2}>
            공개 여부
          </Form.Label>
          <Col sm={10} className={'d-flex align-items-center'}>
            <Form.Check
              inline
              type="radio"
              label="공개"
              name="yearAllow"
              checked={yearAllow === true}
              value={'true'}
              onChange={() => setYearAllow(true)}
              id={'yearAllow-true'}
              disabled={busy}
            />
            <Form.Check
              inline
              type="radio"
              label="비공개"
              name="yearAllow"
              checked={yearAllow === false}
              value={'false'}
              onChange={() => setYearAllow(false)}
              id={'yearAllow-false'}
              disabled={busy}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="yearProductId">
          <Form.Label column sm={2}>
            상품 ID
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              name={'yearProductId'}
              value={yearProductId || ''}
              onChange={e => setYearProductId(e.currentTarget.value)}
              required
              disabled={busy}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="yearPriceId">
          <Form.Label column sm={2}>
            가격 ID
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              name={'yearPriceId'}
              value={yearPriceId || ''}
              onChange={e => setYearPriceId(e.currentTarget.value)}
              required
              disabled={busy}
            />
          </Col>
        </Form.Group>
        <h4 className={'mb-3 mt-5'}>월간 멤버십</h4>
        <Form.Group as={Row} className="mb-3" controlId="monthAllow">
          <Form.Label column sm={2}>
            공개 여부
          </Form.Label>
          <Col sm={10} className={'d-flex align-items-center'}>
            <Form.Check
              inline
              type="radio"
              label="공개"
              name="monthAllow"
              checked={monthAllow === true}
              value={'true'}
              onChange={() => setMonthAllow(true)}
              id={'monthAllow-true'}
              disabled={busy}
            />
            <Form.Check
              inline
              type="radio"
              label="비공개"
              name="monthAllow"
              checked={monthAllow === false}
              value={'false'}
              onChange={() => setMonthAllow(false)}
              id={'monthAllow-false'}
              disabled={busy}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="monthProductId">
          <Form.Label column sm={2}>
            상품 ID
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              name={'monthProductId'}
              value={monthProductId || ''}
              onChange={e => setMonthProductId(e.currentTarget.value)}
              required
              disabled={busy}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="monthPriceId">
          <Form.Label column sm={2}>
            가격 ID
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              name={'monthPriceId'}
              value={monthPriceId || ''}
              onChange={e => setMonthPriceId(e.currentTarget.value)}
              required
              disabled={busy}
            />
          </Col>
        </Form.Group>

        <div className={'text-end mt-3'}>
          <Button type={'submit'} disabled={!ready || busy}>
            저장
          </Button>
        </div>
      </Form>
    </>
  );
};

export default MembershipSubscriptionType;
