import apiClient from '../index';
import { AdminUserSubscriptionListResponse } from './type';

const AdminUserSubscriptionApi = {
  get: async () => {
    return await apiClient.get<AdminUserSubscriptionListResponse>(
      '/admin/user-subscriptions',
    );
  },
};

export default AdminUserSubscriptionApi;
