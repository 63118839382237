import { Container } from 'react-bootstrap';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import Gnb from '../../components/Gnb';
import AdminGnb from '../../components/AdminGnb';

const AdminHomePage = () => {
  return (
    <>
      <Helmet>
        <title>HomePage</title>
      </Helmet>
      <AdminGnb />
      <Container>hihi</Container>
    </>
  );
};

export default AdminHomePage;
