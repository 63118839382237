import { Helmet } from 'react-helmet-async';
import AdminGnb from '../../components/AdminGnb';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import React, { useState } from 'react';
import _ from 'lodash';
import adminVideoApi from '../../api/adminVideoApi';
import adminNotificationApi from '../../api/adminNotificationApi';

const AdminPushSendPage = () => {
  const [busy, setBusy] = useState(false);

  const [receiverType, setReceiverType] = useState(0);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [landingType, setLandingType] = useState('video');
  const [videoId, setVideoId] = useState('');
  const [navigateUrl, setNavigateUrl] = useState('');
  const [notificationType, setNotificationType] = useState(0);

  const onSubmit = async e => {
    e.preventDefault();

    if (busy) {
      return;
    }

    const titleToSend = _.trim(title) || null;
    const contentToSend = _.trim(content) || null;
    const videoIdToSend = _.trim(videoId) || null;
    const navigateUrlToSend = _.trim(navigateUrl) || null;

    if (!titleToSend) {
      alert('제목을 입력하세요.');
      return;
    }

    if (!contentToSend) {
      alert('내용을 입력하세요.');
      return;
    }

    if (landingType === 'video') {
      if (!videoIdToSend) {
        alert('이동할 동영상 Id 를 입력하세요.');
        return;
      }
    }

    if (landingType === 'webview') {
      if (navigateUrlToSend === null) {
        alert('이동할 웹사이트 주소를 입력하세요.');
        return;
      }

      if (
        !navigateUrlToSend.startsWith('http://') &&
        !navigateUrlToSend.startsWith('https://')
      ) {
        alert('이동할 웹사이트 주소가 잘못되었습니다.');
        return;
      }
    }

    setBusy(true);

    if (landingType === 'video') {
      try {
        await adminVideoApi.getById(parseInt(videoIdToSend!!, 10), {
          customErrorHandling: true,
        });
      } catch (_) {
        alert('입력하신 동영상이 등록되어있지 않습니다.');
        setBusy(false);
        return;
      }
    }

    try {
      await adminNotificationApi.send(
        receiverType,
        titleToSend,
        contentToSend,
        landingType,
        videoIdToSend ? parseInt(videoIdToSend, 10) : null,
        navigateUrlToSend,
        notificationType,
      );

      alert('푸시 발송이 요청되었습니다.');
      setTitle('');
      setContent('');
      setVideoId('');
      setNavigateUrl('');
    } catch (_) {}

    setBusy(false);
  };

  const renderContent = () => {
    return (
      <div>
        <Form onSubmit={onSubmit}>
          <fieldset>
            <Form.Group as={Row} className="mb-3">
              <Form.Label as="legend" column sm={2}>
                상태
              </Form.Label>
              <Col sm={10}>
                <Form.Check
                  type="radio"
                  label="전체"
                  name="receiverType"
                  checked={receiverType === 0}
                  value={0}
                  onChange={() => setReceiverType(0)}
                  id={'receiverType-0'}
                  disabled={busy}
                />
                <Form.Check
                  type="radio"
                  label="연간 + 월간 멤버십"
                  name="receiverType"
                  checked={receiverType === 1}
                  value={1}
                  onChange={() => setReceiverType(1)}
                  id={'receiverType-1'}
                  disabled={busy}
                />
                <Form.Check
                  type="radio"
                  label="연간 멤버십"
                  name="receiverType"
                  checked={receiverType === 2}
                  value={2}
                  onChange={() => setReceiverType(2)}
                  id={'receiverType-2'}
                  disabled={busy}
                />
                <Form.Check
                  type="radio"
                  label="월간 멤버십"
                  name="receiverType"
                  checked={receiverType === 3}
                  value={3}
                  onChange={() => setReceiverType(3)}
                  id={'receiverType-3'}
                  disabled={busy}
                />
                <Form.Check
                  type="radio"
                  label="무료 회원"
                  name="receiverType"
                  checked={receiverType === 4}
                  value={4}
                  onChange={() => setReceiverType(4)}
                  id={'receiverType-4'}
                  disabled={busy}
                />
              </Col>
            </Form.Group>
          </fieldset>
          <Form.Group as={Row} className="mb-3" controlId="title">
            <Form.Label column sm={2}>
              제목
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                name={'title'}
                placeholder={'제목을 입력하세요.'}
                value={title}
                onChange={e => setTitle(e.currentTarget.value)}
                disabled={busy}
                required
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="content">
            <Form.Label column sm={2}>
              내용
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                as={'textarea'}
                rows={5}
                name={'content'}
                placeholder={'내용을 입력하세요.'}
                value={content}
                onChange={e => setContent(e.currentTarget.value)}
                disabled={busy}
                required
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label as="legend" column sm={2}>
              클릭시 이동
            </Form.Label>
            <Col sm={10}>
              <div>
                <Form.Check
                  inline
                  type="radio"
                  label="동영상"
                  name="landingType"
                  checked={landingType === 'video'}
                  value={'video'}
                  onChange={() => setLandingType('video')}
                  id={'landingType-0'}
                  disabled={busy}
                  required
                />
              </div>
              <Form.Check
                type="radio"
                label="라이브"
                name="landingType"
                checked={landingType === 'live'}
                value={'live'}
                onChange={() => setLandingType('live')}
                id={'landingType-1'}
                disabled={busy}
                required
              />
              <Form.Check
                type="radio"
                label="웹사이트"
                name="landingType"
                checked={landingType === 'webview'}
                value={'webview'}
                onChange={() => setLandingType('webview')}
                id={'landingType-2'}
                disabled={busy}
                required
              />
            </Col>
          </Form.Group>
          {landingType === 'video' && (
            <Form.Group as={Row} className="mb-3" controlId="videoId">
              <Form.Label column sm={2}>
                이동할 동영상 Id
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type={'number'}
                  name={'videoId'}
                  placeholder={'동영상 Id 를 입력하세요'}
                  value={videoId}
                  onChange={e => setVideoId(e.currentTarget.value)}
                  disabled={busy}
                  required
                />
              </Col>
            </Form.Group>
          )}
          {landingType === 'webview' && (
            <Form.Group as={Row} className="mb-3" controlId="navigateUrl">
              <Form.Label column sm={2}>
                이동할 웹사이트 주소
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  name={'navigateUrl'}
                  placeholder={'웹사이트 주소를 입력하세요.'}
                  value={navigateUrl}
                  onChange={e => setNavigateUrl(e.currentTarget.value)}
                  disabled={busy}
                  required
                />
              </Col>
            </Form.Group>
          )}
          <fieldset>
            <Form.Group as={Row} className="mb-3">
              <Form.Label as="legend" column sm={2}>
                광고성 여부
              </Form.Label>
              <Col sm={10}>
                <Form.Check
                  type="radio"
                  label="광고성"
                  name="notificationType"
                  checked={notificationType === 0}
                  value={0}
                  onChange={() => setNotificationType(0)}
                  id={'notificationType-0'}
                  disabled={busy}
                />
                <Form.Check
                  type="radio"
                  label="필수 공지"
                  name="notificationType"
                  checked={notificationType === 1}
                  value={1}
                  onChange={() => setNotificationType(1)}
                  id={'notificationType-1'}
                  disabled={busy}
                />
              </Col>
            </Form.Group>
          </fieldset>

          <div className={'d-flex'}>
            <div className={'d-inline-block flex-grow-1'}></div>

            <Button
              type={'submit'}
              className={'d-inline-block w-auto me-2'}
              disabled={busy}
            >
              발송하기
            </Button>
          </div>
        </Form>
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>푸시 발송하기</title>
      </Helmet>
      <AdminGnb />
      <Container>
        <h3 className={'mt-4'}>푸시 발송하기</h3>
        <div className={'mt-4'}>{renderContent()}</div>
      </Container>
    </>
  );
};

export default AdminPushSendPage;
