import apiClient from '../index';
import {
  AdminVideoCommentListResponse,
  AdminVideoDeleteResponse,
  AdminVideoItem,
  AdminVideoListResponse,
} from './type';

const AdminVideoApi = {
  getLatest: async (videoCategoryId: number, page: number) => {
    return await apiClient.get<AdminVideoListResponse>('/admin/videos', {
      params: {
        videoCategoryId,
        page,
      },
    });
  },
  getById: async (id: number, options?: object) => {
    return await apiClient.get<AdminVideoItem>(`/admin/videos/${id}`, {
      ...options,
    });
  },
  updateByIdAndParams: async (id: number, params: object) => {
    return await apiClient.put<AdminVideoItem>(`/admin/videos/${id}`, params);
  },
  createByParams: async (params: object) => {
    return await apiClient.post<AdminVideoItem>(`/admin/videos`, params);
  },
  getLatestComments: async (videoId: number | undefined, page: number) => {
    return await apiClient.get<AdminVideoCommentListResponse>(
      '/admin/videos/comments',
      {
        params: {
          videoId,
          page,
        },
      },
    );
  },
  deleteCommentById: async (videoCommentId: number) => {
    return await apiClient.delete<AdminVideoDeleteResponse>(
      `/admin/videos/comments/${videoCommentId}`,
    );
  },
};

export default AdminVideoApi;
