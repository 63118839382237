import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import AdminGnb from '../../components/AdminGnb';
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { AdminLiveInfoResponse } from '../../api/adminLiveApi/type';
import adminVimeoApi from '../../api/adminVimeoApi';
import adminLiveApi from '../../api/adminLiveApi';

const AdminLivePage = () => {
  const [busy, setBusy] = useState(false);

  const [liveInfo, setLiveInfo] = useState<AdminLiveInfoResponse>();
  const [isLive, setIsLive] = useState(false);
  const [vimeoEventId, setVimeoEventId] = useState<string | null>(null);
  const [title, setTitle] = useState<string | null>(null);
  const [thumbnailImageUrl, setThumbnailImageUrl] = useState<string | null>(
    null,
  );
  const [summary, setSummary] = useState<string | null>(null);
  const [chatEmbedUrl, setChatEmbedUrl] = useState<string | null>(null);
  const [membershipOnly, setMembershipOnly] = useState<boolean | null>(null);

  const requestLiveInfo = async () => {
    const res = await adminLiveApi.getLiveInfo();
    setLiveInfo(res.data);
  };

  useEffect(() => {
    if (!liveInfo) {
      return;
    }

    setIsLive(liveInfo.isLive);
    setVimeoEventId(liveInfo.vimeoEventId);
    setTitle(liveInfo.title);
    setThumbnailImageUrl(liveInfo.thumbnailImageUrl);
    setSummary(liveInfo.summary);
    setChatEmbedUrl(liveInfo.chatEmbedUrl);
    setMembershipOnly(liveInfo.membershipOnly);
  }, [liveInfo]);

  useEffect(() => {
    requestLiveInfo();
  }, []);

  const onSubmit = async e => {
    e.preventDefault();

    if (busy) {
      return;
    }

    setBusy(true);

    try {
      await adminLiveApi.updateLiveInfo(
        isLive,
        vimeoEventId,
        title,
        thumbnailImageUrl,
        summary,
        chatEmbedUrl,
        membershipOnly,
      );

      alert('라이브 정보가 정상적으로 변경되었습니다.');
      window.location.reload();
    } catch (_) {
      setBusy(false);
    }
  };

  const onClickFetchVimeoEventInfo = async () => {
    if (busy) {
      return;
    }

    if (!vimeoEventId) {
      return;
    }

    setBusy(true);

    try {
      const res = await adminVimeoApi.getLiveEventInfo(vimeoEventId);
      setTitle(res.data['stream_title'] as string);
      setSummary(res.data['stream_description'] as string);
      setThumbnailImageUrl(res.data['pictures'].base_link as string);
      setChatEmbedUrl(
        res.data['chat_enabled']
          ? (res.data['embed'].chat_embed_source as string)
          : null,
      );
    } catch (_) {}
    setBusy(false);
  };

  return (
    <>
      <Helmet>
        <title>라이브 관리</title>
      </Helmet>
      <AdminGnb />
      <Container>
        <h3 className={'mt-4'}>라이브 관리</h3>

        <Form onSubmit={onSubmit}>
          <Form.Group as={Row} className="mb-3" controlId="isLive">
            <Form.Label column sm={2}>
              라이브 여부
            </Form.Label>
            <Col sm={10} className={'align-self-center'}>
              <Form.Check
                inline
                type="radio"
                label="On"
                name="isLive"
                checked={isLive}
                value={1}
                onChange={() => setIsLive(true)}
                id={'status-1'}
                disabled={busy}
              />
              <Form.Check
                inline
                type="radio"
                label="Off"
                name="isLive"
                checked={!isLive}
                value={2}
                onChange={() => setIsLive(false)}
                id={'status-2'}
                disabled={busy}
              />
            </Col>
          </Form.Group>
          {isLive && (
            <>
              <Form.Group as={Row} className="mb-3" controlId="vimeoEventId">
                <Form.Label column sm={2}>
                  Vimeo 이벤트 ID
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    name={'vimeoEventId'}
                    placeholder={liveInfo?.vimeoEventId || ''}
                    value={vimeoEventId || ''}
                    onChange={e => setVimeoEventId(e.currentTarget.value)}
                    required
                    disabled={busy}
                  />
                </Col>
                <Col sm={2}>
                  <Button
                    className={'w-100'}
                    variant={'success'}
                    onClick={onClickFetchVimeoEventInfo}
                    disabled={busy}
                  >
                    정보 불러오기
                  </Button>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="title">
                <Form.Label column sm={2}>
                  제목
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="text"
                    name={'title'}
                    placeholder={liveInfo?.title || ''}
                    value={title || ''}
                    onChange={e => setTitle(e.currentTarget.value)}
                    required
                    disabled={busy}
                  />
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="thumbnailImageUrl"
              >
                <Form.Label column sm={2}>
                  썸네일
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="text"
                    name={'thumbnailImageUrl'}
                    placeholder={liveInfo?.thumbnailImageUrl || ''}
                    value={thumbnailImageUrl || ''}
                    onChange={e => setThumbnailImageUrl(e.currentTarget.value)}
                    required
                    disabled={busy}
                  />
                  <img
                    src={thumbnailImageUrl || ''}
                    width={200}
                    className={'mt-3'}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="summary">
                <Form.Label column sm={2}>
                  짧은 내용
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    as={'textarea'}
                    rows={1}
                    type="text"
                    name={'summary'}
                    placeholder={liveInfo?.summary || ''}
                    value={summary || ''}
                    onChange={e => setSummary(e.currentTarget.value)}
                    disabled={busy}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="chatEmbedUrl">
                <Form.Label column sm={2}>
                  채팅방 Url
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="text"
                    name={'chatEmbedUrl'}
                    placeholder={liveInfo?.chatEmbedUrl || ''}
                    value={chatEmbedUrl || ''}
                    onChange={e => setChatEmbedUrl(e.currentTarget.value)}
                    required
                    disabled={busy}
                  />
                </Col>
              </Form.Group>
              <fieldset>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label as="legend" column sm={2}>
                    공개범위
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Check
                      type="radio"
                      label="멤버십 전용"
                      name="membershipOnly"
                      checked={membershipOnly === true}
                      value={'true'}
                      onChange={() => setMembershipOnly(true)}
                      id={'membershipOnly-true'}
                      disabled={busy}
                    />
                    <Form.Check
                      type="radio"
                      label="전체 공개"
                      name="membershipOnly"
                      checked={membershipOnly !== true}
                      value={'false'}
                      onChange={() => setMembershipOnly(false)}
                      id={'membershipOnly-false'}
                      disabled={busy}
                    />
                  </Col>
                </Form.Group>
              </fieldset>
            </>
          )}
          <div className={'d-flex mt-4 justify-content-end'}>
            <Button
              type="submit"
              className={'d-inline-block w-auto'}
              disabled={busy}
            >
              {busy ? <Spinner size={'sm'} /> : '적용하기'}
            </Button>
          </div>
        </Form>
      </Container>
    </>
  );
};

export default AdminLivePage;
