import { create } from 'zustand';

interface State {
  nickname: string | null;
  role: string | null;
}

interface Action {
  setNickname: (nickname: string) => void;
  setRole: (userType: string) => void;
}

export const useUserStore = create<State & Action>(set => ({
  nickname: null,
  role: null,
  setNickname: nickname => set({ nickname }),
  setRole: userType => set({ role: userType }),
}));
