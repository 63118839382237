import apiClient from '../index';
import {
  AdminFaqDeleteResponse,
  AdminFaqItem,
  AdminFaqListResponse,
} from './type';

const AdminFaqApi = {
  getFaqs: async (page: number) => {
    return await apiClient.get<AdminFaqListResponse>('/admin/faqs', {
      params: {
        page,
      },
    });
  },
  findById: async (id: number) => {
    return await apiClient.get<AdminFaqItem>(`/admin/faqs/${id}`);
  },
  deleteById: async (id: number) => {
    return await apiClient.delete<AdminFaqDeleteResponse>(`/admin/faqs/${id}`);
  },
  createByParams: async (params: object) => {
    return await apiClient.post<AdminFaqItem>(`/admin/faqs`, params);
  },
  updateByIdAndParams: async (id: number, params: object) => {
    return await apiClient.put<AdminFaqItem>(`/admin/faqs/${id}`, params);
  },
};

export default AdminFaqApi;
