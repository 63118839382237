import apiClient from '../index';
import { AdminMessageRoomItem, AdminMessageRoomListResponse } from './type';
import { AdminMessageListResponse } from '../adminMessageApi/type';

const AdminMessageRoomApi = {
  getLatest: async (skip: number) => {
    return await apiClient.get<AdminMessageRoomListResponse>(
      '/admin/message-rooms',
      {
        params: {
          skip,
        },
      },
    );
  },
  findById: async (id: number) => {
    return await apiClient.get<AdminMessageRoomItem>(
      `/admin/message-rooms/${id}`,
    );
  },
  getMessages: async (messageRoomId: number, idLessThan: number | null) => {
    return await apiClient.get<AdminMessageListResponse>(
      `/admin/message-rooms/${messageRoomId}/messages`,
      {
        params: {
          idLessThan,
        },
      },
    );
  },
  read: async (messageRoomId: number) => {
    return await apiClient.put(`/admin/message-rooms/${messageRoomId}/read`);
  },
};

export default AdminMessageRoomApi;
