import apiClient from '../index';
import { AdminUserStoryListResponse } from './type';

const AdminUserStoryApi = {
  getUserStories: async (page: number) => {
    return await apiClient.get<AdminUserStoryListResponse>(
      '/admin/user-stories',
      {
        params: {
          page,
        },
      },
    );
  },
};

export default AdminUserStoryApi;
