import apiClient from '../index';
import { AdminVideoCategoryItem, AdminVideoCategoryListResponse } from './type';

const AdminVideoCategoryApi = {
  getAll: async () => {
    return await apiClient.get<AdminVideoCategoryListResponse>(
      '/admin/video-categories',
    );
  },
  getById: async (id: number) => {
    return await apiClient.get<AdminVideoCategoryItem>(
      `/admin/video-categories/${id}`,
    );
  },
  updateByIdAndParams: async (id: number, params: object) => {
    return await apiClient.put<AdminVideoCategoryItem>(
      `/admin/video-categories/${id}`,
      params,
    );
  },
  createByParams: async (params: object) => {
    return await apiClient.post<AdminVideoCategoryItem>(
      `/admin/video-categories`,
      params,
    );
  },
};

export default AdminVideoCategoryApi;
