import apiClient from '../index';
import { AdminNotificationSendResponse } from './type';

const AdminNotificationApi = {
  send: async (
    receiverType: number,
    title: string | null,
    content: string | null,
    landingType: string,
    videoId: number | null,
    navigateUrl: string | null,
    notificationType: number,
  ) => {
    return await apiClient.post<AdminNotificationSendResponse>(
      '/admin/notifications',
      {
        receiverType,
        title,
        content,
        landingType,
        videoId,
        navigateUrl,
        notificationType,
      },
    );
  },
};

export default AdminNotificationApi;
