const AuthService = {
  getAccessToken: () => {
    const accessToken = localStorage.getItem('accessToken');
    const expiredAccessToken = AuthService.getExpiredAccessToken();

    return accessToken !== expiredAccessToken ? accessToken : null;
  },
  getRefreshToken: () => {
    const refreshToken = localStorage.getItem('refreshToken');
    const expiredRefreshToken = AuthService.getExpiredRefreshToken();
    return refreshToken !== expiredRefreshToken ? refreshToken : null;
  },
  setAccessToken: (accessToken: string | null) => {
    if (accessToken === null) {
      localStorage.removeItem('accessToken');
    } else {
      localStorage.setItem('accessToken', accessToken);
    }
  },
  setRefreshToken: (refreshToken: string | null) => {
    if (refreshToken === null) {
      localStorage.removeItem('refreshToken');
    } else {
      localStorage.setItem('refreshToken', refreshToken);
    }
  },
  setExpiredAccessToken: (accessToken: string | null) => {
    if (accessToken === null) {
      localStorage.removeItem('expiredAccessToken');
    } else {
      localStorage.setItem('expiredAccessToken', accessToken);
    }
  },
  setExpiredRefreshToken: (refreshToken: string | null) => {
    if (refreshToken === null) {
      localStorage.removeItem('expiredRefreshToken');
    } else {
      localStorage.setItem('expiredRefreshToken', refreshToken);
    }
  },
  getExpiredAccessToken: () => {
    return localStorage.getItem('expiredAccessToken');
  },
  getExpiredRefreshToken: () => {
    return localStorage.getItem('expiredRefreshToken');
  },
};

export default AuthService;
