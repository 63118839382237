import { Button, Col, Form, Image, Row } from 'react-bootstrap';
import React, { useEffect, useRef, useState } from 'react';
import adminAppConfigApi from '../../api/adminAppConfigApi';
import adminFileApi from '../../api/adminFileApi';

const encodeFileToBase64 = (file: File) => {
  const reader = new FileReader();

  reader.readAsDataURL(file);

  return new Promise(
    (resolve: (v: ArrayBuffer | null | undefined | string) => void) => {
      reader.onload = () => {
        resolve(reader.result);
      };
    },
  );
};

const StartScreenBackgroundImage = () => {
  const [ready, setReady] = useState(false);
  const [busy, setBusy] = useState(false);
  const [text, setText] = useState('');
  const [fileList, setFileList] = useState<FileList>();
  const [newFileData, setNewFileData] = useState<
    string | ArrayBuffer | undefined | null
  >();
  const inputFileRef = useRef<HTMLInputElement>(null);

  const requestGetAppConfig = async () => {
    try {
      const res = await adminAppConfigApi.getConfigByKey(
        'StartScreenBackgroundImageUrl',
        { customErrorHandling: true },
      );
      setText(res.data.value || '');
    } catch (_) {}
  };

  const onSubmit = async e => {
    e.preventDefault();

    const file = fileList?.item(0);
    if (!file) {
      alert('신규 이미지가 선택되지 않았습니다.');
      return;
    }

    if (busy) {
      return;
    }

    setBusy(true);
    try {
      const res = await adminFileApi.upload(file);

      await adminAppConfigApi.updateConfigByKeyAndValue(
        'StartScreenBackgroundImageUrl',
        res.data.uploadedUrl,
      );
      alert('적용되었습니다.');
      if (inputFileRef.current) {
        inputFileRef.current.value = '';
      }
      setFileList(undefined);
      await requestGetAppConfig();
    } catch (_) {}
    setBusy(false);
  };

  useEffect(() => {
    const file = fileList?.item(0);

    if (!file) {
      setNewFileData(undefined);
      return;
    }

    (async () => {
      setNewFileData(await encodeFileToBase64(file));
    })();
  }, [fileList]);

  useEffect(() => {
    (async () => {
      await requestGetAppConfig();
      setReady(true);
    })();
  }, []);

  return (
    <>
      <Form onSubmit={onSubmit}>
        <Form.Group as={Row} className="mb-3" controlId="oldImage">
          <Form.Label column sm={2}>
            기존 이미지
          </Form.Label>
          <Col sm={10}>
            <Form.Control value={text || '(없음)'} disabled />
            {text && (
              <div className={'mt-3'}>
                <Image src={text} width={200} />
              </div>
            )}
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="newImage">
          <Form.Label column sm={2}>
            신규 이미지
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              ref={inputFileRef}
              type={'file'}
              accept={'.jpg,.jpeg,.png'}
              placeholder={'사용할 이미지 주소를 입력하세요.'}
              onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.currentTarget.files) {
                  setFileList(e.currentTarget.files);
                } else {
                  setFileList(undefined);
                }
              }}
              required
              disabled={!ready || busy}
            />
            {newFileData && (
              <div className={'mt-3'}>
                <Image src={newFileData.toString()} width={200} />
              </div>
            )}
          </Col>
        </Form.Group>

        <div className={'text-end mt-3'}>
          <Button type={'submit'} disabled={!ready || busy}>
            저장
          </Button>
        </div>
      </Form>
    </>
  );
};

export default StartScreenBackgroundImage;
