import apiClient from '../index';
import { AdminFileUploadResponse } from './type';

const AdminFileApi = {
  upload: async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);

    return await apiClient.post<AdminFileUploadResponse>(
      '/admin/files',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        transformRequest: data => {
          return data;
        },
      },
    );
  },
};

export default AdminFileApi;
