import { Button, Image, Spinner } from 'react-bootstrap';

import { Helmet } from 'react-helmet-async';
import { Container } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import Gnb from '../../components/Gnb';
import { MembershipInfoResponse } from '../../api/membershipApi/type';
import membershipApi from '../../api/membershipApi';
import MembershipBg from './membership-bg.png';
import MembershipRight from './membership-right.png';
import MembershipBottom from './membership-bottom.png';
import ArrowRightSvg from './arrow-right.svg';
import moment from 'moment';

const MeMembershipPage = () => {
  const [membershipInfo, setMembershipInfo] = useState<
    MembershipInfoResponse | undefined
  >(undefined);
  const [ready, setReady] = useState(false);

  const refreshMembershipInfo = async () => {
    const res = await membershipApi.info();
    setMembershipInfo(res.data);
  };

  const onClickChangePaymentMethod = async () => {
    const res = await membershipApi.changePaymentMethod();
    window.location.href = res.data.navigateUrl;
  };

  const onClickCancelSubscription = async () => {
    if (
      !window.confirm(
        '구독을 취소하면 되돌릴 수 없습니다.\n정말 취소하시겠습니까?',
      )
    ) {
      return;
    }
    await membershipApi.cancelSubscribe();
    window.location.reload();
  };

  useEffect(() => {
    (async () => {
      await refreshMembershipInfo();
      setReady(true);
    })();
  }, []);

  const renderContent = () => {
    if (!ready) {
      return (
        <div className={'my-5 py-5 text-center'}>
          <Spinner />
        </div>
      );
    }

    if (!membershipInfo) {
      return null;
    }

    return (
      <>
        <a
          href={'#'}
          className={'text-decoration-none text-white d-block'}
          style={{
            position: 'relative',
          }}
        >
          <Image src={MembershipBg} style={{ width: '100%' }} />
          <Image
            src={MembershipRight}
            style={{ position: 'absolute', top: 0, right: 0 }}
          />
          <Image
            src={MembershipBottom}
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              width: '100%',
            }}
          />
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              paddingLeft: 20,
              paddingRight: 20,
            }}
            className={'d-flex align-items-center'}
          >
            <div>
              <div className={'fw-bold'} style={{ fontSize: 20 }}>
                우주유랑단{' '}
                {membershipInfo.type === 'YEAR' && (
                  <span style={{ color: '#FECF48' }}>PREMIUM</span>
                )}
                {membershipInfo.type === 'MONTH' && <span>BASIC</span>}
              </div>
              <div style={{ fontSize: 14 }}>
                {membershipInfo.startDateTime
                  ? `가입일: ${moment
                      .utc(membershipInfo.startDateTime)
                      .local()
                      .format('yyyy년 M월 D일')}`
                  : 'NELL’S ROOM의 모든 콘텐츠를 자유롭게'}
              </div>
              <a
                href={'/membership'}
                style={{ marginTop: 8, fontSize: 14 }}
                className={'d-block text-decoration-none text-white'}
              >
                멤버십 혜택 보기
                <div className={'d-inline-block ms-2'}>
                  <Image src={ArrowRightSvg} width={12} />
                </div>
              </a>
            </div>
          </div>
        </a>

        {membershipInfo.type !== null && (
          <>
            <h3 style={{ marginTop: 40, fontSize: 20, fontWeight: 'bold' }}>
              구독관리
            </h3>
            {membershipInfo.status === 'ACTIVE' && (
              <>
                <div style={{ marginTop: 16 }}>
                  <a
                    href={'#'}
                    onClick={onClickChangePaymentMethod}
                    className={'d-inline-block text-decoration-none text-white'}
                    style={{ paddingTop: 16, paddingBottom: 16 }}
                  >
                    결제 카드 변경
                  </a>
                </div>
                <div>
                  <a
                    href={'#'}
                    onClick={onClickCancelSubscription}
                    className={'d-inline-block text-decoration-none text-white'}
                    style={{ paddingTop: 16, paddingBottom: 16 }}
                  >
                    구독 취소
                  </a>
                </div>
              </>
            )}
            {membershipInfo.status === 'PENDING_CANCEL' && (
              <>
                <p className={'text-warning'}>구독이 취소된 상태입니다.</p>
                <p className={'text-warning pt-0'}>
                  {moment
                    .utc(membershipInfo.endDateTime)
                    .format('yyyy년 MM월 DD일 HH시 mm분')}{' '}
                  이후 다시 구독할 수 있습니다.
                </p>
              </>
            )}
            <h3 style={{ marginTop: 40, fontSize: 20, fontWeight: 'bold' }}>
              기타
            </h3>
            <a
              href={'/me/membership/address'}
              className={'d-inline-block text-decoration-none text-white'}
              style={{ paddingTop: 16, paddingBottom: 16 }}
            >
              배송지 입력 & 수정
            </a>
          </>
        )}
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title></title>
      </Helmet>
      <Gnb />
      <Container>
        <h3 className={'mt-4'}></h3>
        <div className={'mt-4'}>{renderContent()}</div>
      </Container>
    </>
  );
};

export default MeMembershipPage;
