import { useEffect, useState } from 'react';
import AuthService from '../../services/authService';

export function useAuth() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = AuthService.getAccessToken();
    if (token) {
      // 토큰이 있으면 로그인 상태로 판단
      console.log('setIsLoggedIn to true');
      setIsLoggedIn(true);
    } else {
      // 토큰이 없으면 로그아웃 상태로 판단
      console.log('setIsLoggedIn to false');
      setIsLoggedIn(false);
    }
  }, []);

  return {
    isLoggedIn,
    setIsLoggedIn,
  };
}
