import { Button, Col, Form, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import adminAppConfigApi from '../../api/adminAppConfigApi';

const DefaultMessageContent = () => {
  const [ready, setReady] = useState(false);
  const [busy, setBusy] = useState(false);
  const [text, setText] = useState('');

  const requestDefaultMessageContent = async () => {
    try {
      const res = await adminAppConfigApi.getConfigByKey(
        'DefaultMessageContent',
        { customErrorHandling: true },
      );
      setText(res.data.value || '');
    } catch (_) {}
  };

  const onSubmit = async e => {
    e.preventDefault();

    if (busy) {
      return;
    }

    setBusy(true);
    try {
      await adminAppConfigApi.updateConfigByKeyAndValue(
        'DefaultMessageContent',
        text,
      );
      alert('적용되었습니다.');
      await requestDefaultMessageContent();
    } catch (_) {}
    setBusy(false);
  };

  useEffect(() => {
    (async () => {
      await requestDefaultMessageContent();
      setReady(true);
    })();
  }, []);

  return (
    <>
      <Form onSubmit={onSubmit}>
        <Form.Group as={Row}>
          <Col sm={12}>
            <Form.Control
              as={'textarea'}
              placeholder={'메신저 기본 메시지를 입력하세요.'}
              value={text}
              onChange={e => setText(e.currentTarget.value)}
              required
              disabled={!ready || busy}
            />
          </Col>
        </Form.Group>

        <div className={'text-end mt-3'}>
          <Button type={'submit'} disabled={!ready || busy}>
            저장
          </Button>
        </div>
      </Form>
    </>
  );
};

export default DefaultMessageContent;
