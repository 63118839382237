import { useCallback, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import membershipApi from '../../api/membershipApi';
import { Spinner } from 'react-bootstrap';
import * as React from 'react';

export function MembershipResultPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const handle = useCallback(
    async (
      status: string | null,
      orderCode: string | null,
      type: string | null,
      timeoutAfter: number,
    ) => {
      if (timeoutAfter < new Date().getTime()) {
        throw Error(
          '주문 정보 확인이 지연되고 있습니다. 나중에 다시 시도해주세요.',
        );
      }

      if (!status || !orderCode || !type) {
        throw Error('잘못된 접근입니다.');
      }

      if (status === 'success') {
        const res = await membershipApi.getOrder(orderCode);
        const order = res.data;

        if (order.isCompleted) {
          navigate('/me/membership');
          return true;
        }

        return false;
        // return order.isCompleted;
      } else if (status === 'cancel') {
        throw Error('사용자가 취소했습니다.');
      } else if (status === 'error') {
        throw Error('오류가 발생했습니다.');
      }

      throw Error('일시적인 오류가 발생했습니다.');
    },
    [],
  );

  const pollingHandle = async (
    status: string | null,
    orderCode: string | null,
    type: string | null,
    timeoutAfter: number,
  ) => {
    try {
      if (!(await handle(status, orderCode, type, timeoutAfter))) {
        setTimeout(() => {
          pollingHandle(status, orderCode, type, timeoutAfter);
        }, 1000);
      }
    } catch (e) {
      alert((e as Error).message);
      navigate('/membership');
    }
  };

  useEffect(() => {
    const status = searchParams.get('status');
    const orderCode = searchParams.get('code');
    const type = searchParams.get('type');

    (async () => {
      await pollingHandle(
        status,
        orderCode,
        type,
        new Date().getTime() + 120 * 1000,
      );
    })();
  }, []);

  return (
    <>
      <div className={'my-5 py-5 text-center'}>
        <h3>주문 정보를 확인중입니다.</h3>
        <p>최대 2분이 소요될 수 있습니다. 잠시만 기다려주세요!</p>
        <Spinner className={'mt-4'} />
      </div>
    </>
  );
}
