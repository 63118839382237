import apiClient from '../index';
import { AdminUserAddressListResponse } from './type';

const AdminUserAddressApi = {
  get: async () => {
    return await apiClient.get<AdminUserAddressListResponse>(
      '/admin/user-addresses',
    );
  },
};

export default AdminUserAddressApi;
